import React from 'react';

const CoverageOptions = props => {
  return (
    <div className={props.class}>
        <div className={`infoText ${props.labelClass}`}>Coverage Range:</div>
        <div className="infoText">{props.face_amount_options}</div>
    </div>
  );
};

export default CoverageOptions;
