import React from 'react';
import './QuizQuestion.css';
import CoverageSelect from '../../../../common/CoverageSelect/coverageSelect';

const QuizCoverageSelect = props => {

  return (
    <div className="w-100 mt-gutter-y quizCoverageSelect">
        <CoverageSelect
          livingState={props.livingState}
          years={props.years}
          onChange={props.handleOption}
          coverage={props.coverage}
          highQuotesEnabled={props.highQuotesEnabled}
          childrenApplication={props.childrenApplication}
        />
        <div className="errWrapper">
          <small className="error quizErr d-block">{props.error_coverage}</small>
        </div>
  </div>
  );
};

QuizCoverageSelect.propTypes = {
};

export default QuizCoverageSelect;
