import React from 'react';
import PropTypes from 'prop-types';
import './QuizQuestion.css';
import QuizButton from './QuizButton';

const QuizTwoOptionsSelect = props => {
  return (
    <div className='d-flex justify-content-center quizTwoOptionsSelect'>
       {
         props.options.map((option, i)=> 
          <QuizButton 
            key={`quiz_btn_${i}_${option}`} 
            option={option} 
            index={i} 
            onClickEvent={props.handler}
            btnClass={props.btnClass}
            selected={props[props.value] && option.toLowerCase() == props[props.value]}
          />)  
       } 
    </div>
  );
};

QuizTwoOptionsSelect.propTypes = {
  options: PropTypes.array.isRequired,
};

export default QuizTwoOptionsSelect;
