import {
    GET_STATES, 
    AUTHENTICATE, 
    AUTHENTICATE_ERROR,
    LOGIN,
    LOGIN_ERROR,
    SET_LOADING,
    TAB_1_AGENT_QUOTES,
    TAB_2_AGENT_QUOTES,
    TAB_3_AGENT_QUOTES
} from '../types'

const initialState = {
    states: [],
    loading: true,
    user: null,
    authError: false,
    loginSuccess: false,
    loginError: null,
    tabQuotes: {
      "1": { quotes: [], stateCarriersWithoutQuotes: [], competitorQuotes: [] },
      "2": { quotes: [], stateCarriersWithoutQuotes: [], competitorQuotes: [] },
      "3": { quotes: [], stateCarriersWithoutQuotes: [], competitorQuotes: [] }
    },
    tab: null,
    tabLoading: {
        1: false,
        2: false,
        3: false
    }
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_STATES:
        return {
            ...state,
            states: action.payload,
            loading: false,

        }

        case AUTHENTICATE:
            return {
                ...state,
                user: action.payload,
                loading: false
    
        }

        case AUTHENTICATE_ERROR:
            return {
                ...state,
                authError: true,
                loading: false
        }

        case LOGIN:
            return {
                ...state,
                loginSuccess: action.payload,
                loading: false
        }

        case LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
                loading: false 
        }

        case TAB_1_AGENT_QUOTES: 
            let firstTabQuotes = Object.assign({}, state.tabQuotes);
            firstTabQuotes["1"] = {
                quotes: action.payload.quotes, 
                stateCarriersWithoutQuotes: action.payload.state_carriers_without_quotes,
                competitorQuotes: action.payload.competitor_quotes
            }
            let loadingTabValue1 = Object.assign({}, state.tabLoading);
            loadingTabValue1["1"] = false;
            return {
                ...state,
                tabQuotes: firstTabQuotes,
                tabLoading: loadingTabValue1,
                tab: action.tab
        }

        case TAB_2_AGENT_QUOTES: 
            let secondTabQuotes = Object.assign({}, state.tabQuotes);
            secondTabQuotes["2"] = {
                quotes: action.payload.quotes, 
                stateCarriersWithoutQuotes: action.payload.state_carriers_without_quotes,
                competitorQuotes: action.payload.competitor_quotes
            }
            let loadingTabValue2 = Object.assign({}, state.tabLoading);
            loadingTabValue2["2"] = false;
        return {
            ...state,
            tabQuotes: secondTabQuotes,
            tabLoading: loadingTabValue2,
            tab: action.tab
        }

        case TAB_3_AGENT_QUOTES: 
            let thirdTabQuotes = Object.assign({}, state.tabQuotes);
            thirdTabQuotes["3"] = {
                quotes: action.payload.quotes, 
                stateCarriersWithoutQuotes: action.payload.state_carriers_without_quotes,
                competitorQuotes: action.payload.competitor_quotes
            }
            let loadingTabValue3 = Object.assign({}, state.tabLoading);
            loadingTabValue3["3"] = false;
        return {
            ...state,
            tabQuotes: thirdTabQuotes,
            tabLoading: loadingTabValue3,
            tab: action.tab
        }

        case SET_LOADING: 
        let loadingTabValue = Object.assign({}, state.tabLoading);
        loadingTabValue[action.tab] = action.payload;
        return {
            ...state,
            tabLoading: loadingTabValue
         }

        default: return state
    }

}