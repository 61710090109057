import React, { Component } from 'react';
import ConfirmationLayout from '../components/ConfirmationLayout';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {updateProgressBar} from '../../../store/actions/consumerActions';
class Confirmation extends Component {
  constructor(props) {
    super(props);

    const reference_number = sessionStorage.getItem('reference_number');
    const agent_phone_number = sessionStorage.getItem('agent_phone_number');
    this.state = {reference_number: reference_number, agent_phone_number: agent_phone_number};
    sessionStorage.setItem('application_id', '');
  }

  handleBackChoiceMutual = e => {
    const previousUrl = JSON.parse(sessionStorage.getItem('userInfo') || '{}').previousUrl;
    if (previousUrl != undefined)
      window.parent.location.replace(previousUrl);
    else
    window.parent.location.replace('https://choicemutual.com/');
  }

  componentDidMount() {
    document.querySelector('header').scrollIntoView();
    this.props.updateProgressBar(true);
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1);
    };
  }

  render() {
    return (
      <ConfirmationLayout
        reference_number = {this.state.reference_number}
        agent_phone_number = {this.state.agent_phone_number}
        handleBackChoiceMutual = {this.handleBackChoiceMutual} />
    );
  }
}

const mapStateToProps  = (state) => ({
})

export default compose(
  connect(mapStateToProps, {updateProgressBar}))(Confirmation);