import React from 'react';
import './Application.css';
import { capitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter';
import CarrierLogo  from '../../../utils/CarrierLogo';
import Divider from '../../Common/Divider'
import ApplicationForm from './ApplicationForm';
import Benefits from '../../../common/Benefits/components/Benefits';
import { isMacOS } from '../../../utils/isMacOS';

function CoverageText(coverage, mooQuoteGI, insurance_amount_st){
  if (coverage && mooQuoteGI && insurance_amount_st){
    const mooQuoteGIFloat = parseFloat(mooQuoteGI)
    if (parseInt(coverage.replace(/,/,''),10) <= 25000 && 
      parseFloat(insurance_amount_st) < mooQuoteGIFloat)
      return ` at $${mooQuoteGIFloat.toFixed(2)}/month for the same $${coverage} in coverage`
  }
  return ''
}

const ApplicationInfo = props => {
  const { metaInfo, policyTypeText } = props

  return (
    <div className="info-box">
      <div className="col-lg-6 px-0 col-info">
        <div className="policy-wrapper d-flex flex-column">
          <h5>Your Insurance Policy</h5>
          <div className="resume row mx-0 justify-content-center">
            <div className='d-flex app-info-wrapper'>
              <div className="px-0 d-flex flex-column application-info col-md-7 col-lg-12">
                <h5 className="fw-500">Insurer Information</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column justify-content-end">
                    <div className="infoText fs-15">Company</div>
                    <h4 className="mb-0">{props.name}</h4>
                  </div>
                  <CarrierLogo name={props.name} className={`${props.name && props.name.split(" ").join("")} carrierLogo`}/>
                </div>

                <div className="d-flex flow-column text-left justify-content-between align-items-center premium-coverage">
                  <div>
                    <div className="infoText fs-15">Policy Type</div>
                    <h4 className="fs-18 mb-0">{policyTypeText}</h4>
                  </div>
                  <div className="premium">
                    <div className="infoText fs-15">Premium</div>
                    <h4>${props.insurance_amount_st}<span className="d-inline quote-value-mo">/mo</span></h4>
                  </div>
                  <div className="coverage">
                    <div className="infoText fs-15">Coverage</div>
                    <h4>${props.coverage}</h4>
                  </div>
                </div>

                <Divider parentClass="px-0 submit-app"/>

                <h5>Insured Information</h5>

                <div className="d-flex text-left justify-content-between client-info">
                  <div>
                    <div className="infoText fs-15">Age</div>
                    <h4 className="navSecondaryItems mx-auto">{props.years}</h4>
                  </div>
                  <div>
                    <div className="infoText fs-15">Gender</div>
                    {props.livingState === 'Montana' ? (
                      <h4 className="mx-auto">Male</h4>
                    ) : (
                      <h4 className="navSecondaryItems  mx-auto">{capitalizeFirstLetter(props.gender)}</h4>
                    )}
                  </div>
                  <div>
                    <div className="infoText fs-15">State</div>
                    <h4 className="navSecondaryItems  mx-auto">{props.livingState}</h4>
                  </div>
                  <div>
                    <div className="infoText fs-15">Tobacco</div>
                    <h4 className="navSecondaryItems  mx-auto">{props.smoked === "yes" ? "User" : "Non-user"}</h4>
                  </div>
                </div>
              </div>

              <Divider parentClass="px-0 submit-app d-submit-none"/>
              <div className="horizontal-divider"></div>            
              <div className={`d-flex flex-column benefits-col px-0 ${props.productType === "final_expense" ? "final-expense" : ""}`}>
                <h5 className="fs-18">{props.productType == "term_life" ? 'Plan Features' : 'Plan Benefits'}</h5>
                  <Benefits 
                    productType={props.productType} 
                    plan_type={props.plan_type}
                    applicationInfo
                    metaInfo={metaInfo}
                    containerClass="mt-0 submit-benefits" 
                    secondContainerClass="py-0" 
                    listContainerClass="mt-0"
                  />
              </div>
            </div>

            <Divider parentClass={`submit-app ${props.name != "Mutual of Omaha" && 'd-none'}`}/>
            {
              props.name === "Mutual of Omaha" &&
                <div className={`d-flex flex-column policy-note `}>
                  <h5 className="fs-18 mb-2">Important Policy Note</h5>
                  {metaInfo && <div className="navSecondaryItems font-weight-normal mt-1">
                    {metaInfo.coverage_text.line_1}{" "}
                    {metaInfo.coverage_text.line_2}
                    {CoverageText(props.coverage, props.mooQuoteGI, props.insurance_amount_st)}
                  </div>}
                </div>
            }
          </div>
        </div>
      </div>
      <div className="col-lg-6 px-0 col-form">
        <ApplicationForm {...props} />
      </div>
    </div>
  );
};

export default ApplicationInfo;
