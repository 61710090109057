import React, {useState} from 'react';
import Vector from '../../../assets/Quotes/vector.svg';
import Dot from '../../../assets/Quotes/dot.svg';
import ionOpenOutline from '../../../assets/Benefits/ion_open-outline.svg'
import ionOpenOutlineHovered from '../../../assets/Benefits/ion_open-outline_hovered.svg'
import { isChrome } from '../../../utils/Browsers';
import { isWindows } from '../../../utils/isWindows';
import './Benefits.css';

const Benefits = (props) => {
  const [isHovered, setIsHovered] = useState(false); 
  const { productType, plan_type, applicationInfo, secondContainerClass, listContainerClass, metaInfo, togglePlanModal } = props;
  const rises_every = metaInfo ? metaInfo['rises_every'] : 5
  const expire_at = metaInfo ? metaInfo['expire_at'] : 80

  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const imageSrc = isHovered ? ionOpenOutlineHovered : ionOpenOutline;
  const guaranteed_acceptance = plan_type === "Guaranteed Acceptance"

  return (
    <div className={`d-flex flex-column col-xs-12 px-0 benefits mtBenefits ${props.containerClass}`}>
        {!applicationInfo && <div className="divider"></div>}
          <div className={`d-flex flex-column benefitsListWrapper ${secondContainerClass}`}>
            {!applicationInfo && <h3 className="fs-18 my-0">Details</h3>}
            <div className={`d-flex flex-column benefitsListContainer fs-15 ${listContainerClass}`}>
              {!applicationInfo && `All ${productType === "final_expense" ? "Whole" : "Term"} Life insurance plans ${productType === "final_expense" ? "offer" : "includes"} these ${productType === "final_expense" ? "benefits" : "features"}:`}
              {productType === "final_expense" ?
                <div className={`d-flex benefitsColsWrapper fs-15 ${!applicationInfo ? 'mt-3' : ''}`}>
                  <div className='d-flex flex-column benefitsCols'>
                    <div></div> {/* this empty div allows aligning benefit items with space-between when the screen > 753 */}
                    <div className="d-flex align-items-center mb-1">
                      <img className="mr-2" src={Vector}></img>
                      Premiums never increase
                    </div>
                    <div className="d-flex align-items-center my-1">
                      <img className="mr-2" src={Vector}></img>
                      Coverage never decreases
                    </div>
                    <div className="d-flex align-items-center my-custom-last">
                      <img className="mr-2" src={Vector}></img>
                      Policy never expires
                    </div>
                  </div>
                  <div className='d-flex flex-column benefitsCols'>
                    <div></div> {/* this empty div allows aligning benefit items with space-between when the screen > 753 */}
                    {!guaranteed_acceptance && <div className="d-flex align-items-center my-custom-first">
                      <img className="mr-2" src={Vector}></img>
                      No medical exam {applicationInfo ? "required" : "to qualify"}
                    </div>}
                    {guaranteed_acceptance && <div className="d-flex align-items-center my-custom-first">
                      <img className="mr-2" src={Vector}></img>
                      No health questions
                    </div>}
                    {!guaranteed_acceptance && !applicationInfo && <div className="d-flex align-items-center my-1">
                      <img className="mr-2" src={Vector}></img>
                      No money due upfront
                    </div>}
                    {applicationInfo && <div className="d-flex align-items-center my-1">
                      <img className="mr-2" src={Vector}></img>
                      {guaranteed_acceptance ? 'Full benefit after 2 years' : 'No 2-year waiting period'}
                    </div>}
                    {applicationInfo && <div className="d-flex align-items-center mt-1">
                      <img className="mr-2" src={Vector}></img>
                      {productType === "final_expense" ? "Whole Life" : "Term Life"} insurance
                    </div>}
                  </div> 
                </div> 
              :
                <div className='d-flex benefitsColsWrapper term-life fs-15'>
                  <div className='d-flex flex-column benefitsCols'>
                    <div className="d-flex align-items-center mb-1">
                      <img className="mr-2" src={Dot}></img>
                      Coverage never decreases
                    </div>
                    <div className="d-flex align-items-center my-custom-last">
                      <img className="mr-2" src={Dot}></img>
                      No medical exam required
                    </div>
                  </div>
                  <div className='d-flex flex-column benefitsCols'>
                    <div className="d-flex align-items-center my-custom-first">
                      <img className="mr-2" src={Dot}></img>
                      Price rises every {rises_every} years
                    </div>
                    <div className="d-flex align-items-center mt-1">
                      <img className="mr-2" src={Dot}></img>
                      Expires at age {expire_at}
                    </div>
                  </div>
                </div>
              }
            </div>

            {!applicationInfo && <div 
              className={`seeMore mt-3 d-flex align-items-center fw-500`}
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}           
              onClick={togglePlanModal} >
              <div><span>SEE MORE</span></div>
              <img className={`ml-2 mo-firefox-margin ${isChrome() && isWindows() ? "pb-1" : ""}`} src={imageSrc} ></img>
            </div>}

          </div>
          {!applicationInfo && <div className="divider"></div>}
    </div>
  );
};

export default Benefits;
