import React, { useEffect, useState } from 'react';
import infoIcon from '../../../../assets/Quotes/infoIcon.svg';

const NotAvailable = props => {
  const {planKey, metaInfo, reason, state, ageAvailability, age, colonialPenn, setCoverageTooltip} = props;
  const hasInfo = metaInfo && metaInfo.hasOwnProperty("available_coverages_info_text");
  const [isRetina, setIsRetina] = useState(false);

  useEffect(() => {
    const checkRetina = () => {
      const mediaQuery = window.matchMedia(
        'only screen and (min-resolution: 2dppx)'
      );
      const isRetinaDisplay = mediaQuery.matches;

      const isHighDPI =
        (window.matchMedia &&
          (window.matchMedia(
            'only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx), only screen and (min-resolution: 1.5)'
          ).matches ||
            window.matchMedia(
              'only screen and (min-resolution: 2dppx), only screen and (min-resolution: 2)'
            ).matches)) ||
        (window.devicePixelRatio && window.devicePixelRatio > 1.5);

      setIsRetina(isRetinaDisplay || isHighDPI);
    };

    checkRetina();

    window.addEventListener('resize', checkRetina);

    return () => {
      window.removeEventListener('resize', checkRetina);
    };
  }, []);

  const ageDisqualified = (age, ageAvailability) => {
    const ageMin = parseInt(ageAvailability.split("-")[0], 10);
    const ageMax = parseInt(ageAvailability.split("-")[1], 10);

    return (<div className="d-flex flex-column text-center breakWord">
              <div>Not available for people</div>
              <div>{age > ageMax ? "over":"under"} age {age > ageMax ? ageMax : ageMin}</div>
            </div>)
  };

  return (
    <div className="d-flex justify-content-center align-items-md-center justify-content-md-end notAvailable">
        {
          //heirarchy is State-->Age-->coverage
        }
        <div className='fs-15 fw-500'>
         {reason.includes("state") ? 
            `Not available in ${state}` 
          : reason.includes("age") ? 
            ageDisqualified(age, ageAvailability) 
          : (<div className={`d-flex flex-column text-center ${hasInfo ? "breakWordWithInfoIcon" : "breakWord"}`}>
              <div>Not available for chosen</div>
              <div className="d-flex align-items-center justify-content-center p-relative">
                <div className={`d-flex align-items-center ${hasInfo ? "mr-2" : ""}`}>coverage amount</div>
                {
                  hasInfo && 
                  <div
                    className="infoIconWrapper d-flex align-items-center"
                    onMouseEnter={()=> setCoverageTooltip(planKey, metaInfo.available_coverages_info_text)} 
                    onMouseLeave={()=> setCoverageTooltip(null, null)} 
                >
                  <img
                    src={infoIcon}
                    alt="icon"
                    className="infoIcon"
                  />
                </div>
                }
              </div>
            </div>)
         }
         {
            colonialPenn &&
            reason === " face_amount" &&
            <div className="fs-13 font-weight-normal deepGray text-center text-sm-left">
              <div className="d-flex flex-column flex-sm-row-cp align-items-center justify-content-left">
                <div className="mr-2">1 unit @ {metaInfo.unit_cost} each</div>
                <div className="ellipse mr-2 d-none d-sm-block-cp"></div>
                Coverage Per Unit: {metaInfo.coverage_per_unit}
              </div>
              {metaInfo.maximum_coverage}
            </div>
         }
        </div>
    </div>
  );
};

export default NotAvailable;
