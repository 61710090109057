import React from 'react';
import EditInfoLink from '../../../../common/Buttons/components/EditInfoLink';
import BackButton from '../../../../common/Buttons/components/BackButton';
import deepQuoteExploration from '../../../../assets/Quotes/deep_quote_exploration.svg';
import './DeepQuoteExploration.css'

const DeepQuoteExploration = props => {
  let coverage_amount = props.coverage
  let highQuotesEnabled = props.highQuotesEnabled == 'true'
  return (
    <div className="deep-quote-exploration col-12 px-0">
      <div className="deep-quote-exploration-title d-flex align-items-center justify-content-center text-center px-2">
        <h2 className="my-auto">You’ve activated our deep quote exploration mode!</h2>
      </div>
      <div className="content">
        <figure className="text-center">
          <img src={deepQuoteExploration} alt="no quotes" />
        </figure>
        <p>
          We appreciate your inquiry. We’re always happy to find you quotes that suit specific needs. 
        </p>
        <br />
        <p>
          To accurately give you a quote for ${coverage_amount} in whole life insurance, we’ll need to have a Choice Mutual agent ask you a few clarifying questions.
        </p>
        <br />
        <p>
          One of our agents will call you within the next business day. We won't need much of your time.
        </p>
        <br />
        <div className="row buttonsWrapper px-0 mx-0">
          <div className="back-btn-wrapper col-12 col-sm-7 order-sm-2 px-0">
            <BackButton route="//choicemutual.com" text="Return to Choice Mutual" />
          </div>
          <div className="edit-btn col-12 col-sm-5 px-0 d-sm-flex justify-content-md-end editInfoWrapper">
            <EditInfoLink route={highQuotesEnabled ? '/?high_quotes_enabled=true' : '/'} text="Edit Application" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeepQuoteExploration;
