import React from 'react';
import './QuizQuestion.css';

const QuizContactInformation = props => {

  return (
    <div className="w-100 mt-gutter-y quizContactInformation">
        <div className="input-container col-12 px-0 mt-gutter-y" name="name-wrapper" id="name-wrapper">
          <input 
            type="text" 
            className={`form-control p-relative`} 
            name={props.childrenApplication ? "owner_name":"insured_name"}
            id={props.childrenApplication ? "owner_name":"insured_name"}
            autoComplete={props.childrenApplication ? "owner-name":"insured-name"}
            defaultValue={props.childrenApplication ? props.owner_name : props.insured_name}
            onBlur={props.handleBlur}
            required
          />
            <label className={`infoText ${(props.insured_name || props.owner_name) && 'filled'}`}>Your Name</label>
            <div className="errWrapper d-flex align-items-center">
              <small className="error">{props.error_name}</small>
            </div>
        </div>

        <div className="input-container col-12 px-0 mt-gutter-y" name="email-wrapper" id="email-wrapper">
            <input 
              type="email"
              className={`form-control`}
              name="email"
              id="email"
              defaultValue={props.email}
              onBlur={props.handleBlur}
              autoComplete="email-field"
            />
            <label className={`infoText ${props.email && 'filled'}`}>Email</label>
            <div className="errWrapper d-flex align-items-center">
              <small className="error">{props.error_email}</small>
            </div>
        </div>

        <div className="input-container col-12 px-0 mt-gutter-y" name="phone-wrapper" id="phone-wrapper">
            <input
              type="tel"
              className={`form-control`}
              name="mainPhone"
              id="mainPhone"
              maxLength="14"
              defaultValue={props.mainPhone}
              onBlur={props.handleBlur}
              onKeyUp={props.validateNumber}
              autoComplete="mainPhone"
            />
            <label className={`infoText ${props.mainPhone && 'filled'}`}>Phone Number</label>
            <div className="errWrapper d-flex align-items-center">
              <small className="error">{props.error_phone}</small>
            </div>
        </div>

        <div className="fs-12 c-bluegreen-dp text-center">
          We will never sell or share your info with anybody.
        </div>

        <p
          id="submit-btn"
          name="submit-btn"
          className="quoteBtn w-100 fw-700"
          onClick={props.handleDetailsSubmit}                       
        >
          See Instant Quotes
        </p>

        <div className="fs-generalinfo-disclaimer mt-3">
            By pressing "See Instant Quotes" you agree to our privacy policy and consent to have a Choice Mutual agent contact you by email, phone call, text/SMS message at the phone number and email you provide. Consent isn’t a condition to purchase our products.
        </div>
     </div>
  );
};

export default QuizContactInformation;
