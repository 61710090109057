import React from 'react';
import PropTypes from 'prop-types';
import './QuizQuestion.css';
import Check from '../../../../assets/Quiz/Check.svg';
import { isMacOS } from '../../../../utils/isMacOS';

const QuizButton = props => {
   const {selected, onClickEvent} = props;

  return (
    <div 
      id={`quizButton_${props.btnClass}_${props.option}`}
      onClick={()=> onClickEvent(props.option)} 
      className={`d-flex align-items-center justify-content-center quizBtn cPointer ${props.index == 0 ? `mr-3` : ""} ${props.btnClass} ${selected ? "selected":""}`}>
      <img 
        id={`selectedImg_${props.btnClass}_${props.option}`}
        src={Check} 
        className={`selectedCheck mr-2 ${selected ? "d-block": "d-none"}`} />
      <h5 className={`${!isMacOS() && "not-mac-os"}`}>{props.option}</h5>
    </div>
  );
};

QuizButton.propTypes = {
 option: PropTypes.string.isRequired,
 index: PropTypes.number.isRequired,
 btnClass: PropTypes.string,
};

export default QuizButton;
