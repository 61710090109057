import {
    GET_QUOTES, 
    UPDATE_PROGRESS_BAR,
    SET_LOADING_CONSUMER
} from '../types'

const initialState = {
    quotes: [],
    featured_quotes: [],
    shouldUpdateProgressBar: false,
    loading: false,
}

export default function(state = initialState, action){

    switch(action.type){
        case GET_QUOTES: 
          const featured_quotes = [
            ...action.payload.featured_immediate_coverage_plan,
            ...action.payload.featured_guaranteed_issue_plan
          ];

          sessionStorage.setItem('license', action.payload.license);
          sessionStorage.getItem('application_id', action.payload.application_id);

          sessionStorage.setItem(
            'featured_quotes',
            JSON.stringify(featured_quotes)
          );

          sessionStorage.setItem('quotes', JSON.stringify(action.payload.quotes));
        return {
          ...state,
          quotes: action.payload.quotes,
          featured_quotes,
          loading: false
        }

        case UPDATE_PROGRESS_BAR: 
        return {
            ...state,
            shouldUpdateProgressBar: action.payload
        }
        
        case SET_LOADING_CONSUMER: 
        return {
            ...state,
            loading: action.payload
         }

        default: return state
    }

}