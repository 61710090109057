import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './common/Header/components/Header';
import Footer from './common/Footer/Footer';
import Reviews from './common/Reviews/Reviews';
import Details from './pages/Details/containers/Details';
import Quotes from './pages/Quotes/containers/Quotes';
import Application from './pages/Application/containers/Application';
import Confirmation from './pages/Confirmation/containers/Confirmation';
import QuoteOnly from './pages/QuoteOnly/containers/QuoteOnly';
import ChildrenApplication from './pages/ChildrenApplication/containers/ChildrenApplication';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-ZS2WRMJ1SL');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerVisible: true,
      headerMode: "default",
      containerMode: "default",
      logoLink: true,
      progressBar: true
    };

    this.toggleHeader = this.toggleHeader.bind(this);
    this.headerMode = this.headerMode.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth < 992) {
      window.addEventListener('popstate', () => {
        if (document.querySelector('.fixed-btn-mobile')) {
          document
            .querySelector('.fixed-btn-mobile')
            .classList.remove('d-none');
        }
      });
    }
  }

  toggleHeader(visibility) {
    this.setState({ headerVisible: visibility });
  }

  headerMode(mode) {
    this.setState({ headerMode: mode });
  }

  containerMode = (mode) => {
    this.setState({containerMode: mode});
  }

  logoLink = (value) => {
    this.setState({logoLink: value});
  }

  toggleProgressBar = (visibility) => {
    this.setState({ progressBar: visibility });
  }

  render() {
    return (
      <Router>
        <div className="App container-fluid px-0">
          {this.state.headerVisible && <Header mode={this.state.headerMode} logoLink={this.state.logoLink} progressBar={this.state.progressBar} />}
          <div>
            <div className="mx-0">
              <Switch>
                <Route exact path="/" render={(props)=> (
                  <Details {...props} />
                )}  
                />
                <Route exact path="/quotes-list"
                  render={()=> (
                    <Quotes logoLink={this.logoLink} />
                  )}  
                />
                <Route exact path="/application" render={(props)=> (
                  <Application {...props} />
                )}
                />
                <Route exact path="/confirmation" render={(props)=> (
                  <Confirmation {...props} />
                )} />
                <Route exact path="/quote-only" component={QuoteOnly} />
                <Route exact path="/children-application" render={(props)=> (
                  <ChildrenApplication {...props} toggleProgressBar={this.toggleProgressBar} />
                )}
                />
              </Switch>
            </div>
          </div>
          <Reviews/>
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
