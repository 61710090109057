import React from 'react';
import quickTime from '../../../assets/Cards/quickTime.svg';
import checkShield from '../../../assets/Cards/checkShield.svg';
import featuredImg from '../../../assets/Cards/Featured_ribbon.svg';
import Divider from '../../Common/Divider'
import CoverageOptions from './CoverageOptions/CoverageOptions';
import DownloadPolicy from './DownloadPolicy/DownloadPolicy';
import CarrierLogo from '../../../utils/CarrierLogo';
import { isChrome } from '../../../utils/Browsers';
import { isWindows } from '../../../utils/isWindows';

export default props => {
  const { name, rating, insurance_amount_st, plan_type, face_amount_options, policy_pdf_path} = props.quote;
  // If there's only one featured quote, it should occupy the full width on +960 screens
  const { unique } = props;

  return (
    <div className={`col-12 col-mdxl-12 ${unique ? "col-md-12" : "col-md-6 col-lg-6"} px-0 cardWrapper ${unique ? "unique" : ""}`}>
      <div className={`card justify-content-sm-center mx-mdxl-auto ${props.index == 0 ? "ml-sm-auto" : "mr-sm-auto"} ${unique && "unique"}`}>
      {plan_type === 'Guaranteed Issue Plan' ? (
        <div className="card-header text-white d-flex align-items-xs-center justify-content-md-start">
          <img className="giImg" src={checkShield} alt="" />
          <h5 className="ml-05rem mb-0 fw-600">{plan_type} Plan</h5>
        </div>
      ) : (
        <div className="card-header text-white d-flex align-items-xs-center justify-content-md-start">
          <img className="immediateImg" src={quickTime} alt="" />
          <h5 className="ml-05rem mb-0 fw-600">{plan_type} Plan</h5>
        </div>
      )}
      <div className="card-body pt-0 p-1rem">
        <img className="featured" src={featuredImg} alt="" />
        
        <div className="d-flex flex-column mt-featured mx-auto order-sm-1 mx-sm-0 col-12 px-0 order-md-1 d-md-flex col-md-12 flex-md-column justify-content-sm-between order-mdxl-1 col-mdxl-12 col-lg-12 justify-content-lg-between">
          <div className={`d-flex flex-column flex-sm-row mb-0 mb-sm-3 mb-md-0 mb-mdxl-3 mb-lg-0 mx-auto mx-sm-0 flex-mdxl-row ${unique ? "" : "flex-md-column flex-lg-column"} justify-content-sm-between col-md-12 px-sm-0`}>
            <div className={`d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-start px-0 order-sm-2 order-mdxl-2 ${unique ? "order-md-2" : "justify-content-md-start order-md-1 order-lg-1"} align-items-mdxl-start`}>
              <CarrierLogo
                className={`${name.split(" ").join("")} logo mx-md-auto`}
                name={name}
              />
            </div>

            <div className={`d-flex justify-content-center justify-content-sm-start ml-mdxl-0 order-sm-1 align-items-mdxl-start flex-md-column ${unique ? "align-items-md-start" : "align-items-md-center align-items-lg-center"}`}>
              <div className={`align-items-mdxl-start d-md-flex flex-md-column ${unique ? "" : "align-items-md-center align-items-lg-center"}`}>
                <h5 className='mt-2 mb-1 mx-auto mx-sm-0 fs-18 mt-md-2 mt-mdxl-2 fw-600'>
                    {name}
                </h5>

                <div className={`d-none align-items-center col-12 col-mdxl-6 priceWrapper d-sm-flex d-md-flex px-md-0 align-items-mdxl-center justify-content-mdxl-start ${unique ? "col-md-6" : "col-md-12 justify-content-md-center mt-md-0 justify-content-lg-center col-lg-12 d-lg-flexjustify-content-lg-center mt-lg-0 col-lg-3"} px-0`}>
                  <div className="d-flex text-nowrap h-34">
                    <h3 className="fs-26 lh-34">${insurance_amount_st}</h3>
                    <p className={`mb-0 pl2 lh-26 fs-18 ${isChrome() && isWindows() ? "lh-40" : "lh-38"}`}> /mo</p>
                  </div>
                </div>
              </div>

              <div className={`d-none align-items-center ratingWrapper mx-auto mx-sm-0 px-sm-0 mt-3 mt-md-1 mt-sm-2 justify-content-center justify-content-sm-start justify-content-mdxl-start ${unique ? "" : "justify-content-md-center justify-content-lg-center"} d-md-flex`}>
                  <p className="raiting navSecondaryItems mr4 my-sm-0 d-flex align-self-center">{rating}</p>
                  <p className="infoText my-sm-0 d-flex align-self-center">A.M Best Rating</p>
              </div>

              <CoverageOptions 
                face_amount_options={face_amount_options} 
                class="col-12 d-none justify-content-center align-items-center align-items-md-start text-center mx-auto mx-sm-0 justify-content-sm-start px-0 justify-content-md-center d-md-flex mt-md-1" 
                labelClass="featuredCovOptions"
              />
            </div>
          </div>

          <div className={`d-flex justify-content-center align-items-center col-12 priceWrapper d-sm-none px-md-0 justify-content-md-end mt-md-0 mb-3 d-mdxl-none ${unique ? "" : "px-lg-0 justify-content-lg-end mt-lg-0 col-lg-3"}`}>
            <div className="d-flex text-nowrap h-34">
              <h3 className="fs-26 lh-34">${insurance_amount_st}</h3>
              <p className="mb-0 pl2 lh-26 fs-18 lh-38"> /mo</p>
            </div>
          </div>

          <div className="d-flex align-items-center ratingWrapper mx-auto mx-sm-0 px-sm-0 mt-1 justify-content-center justify-content-sm-start d-md-none">
            <p className="raiting navSecondaryItems mr4 my-sm-0 d-flex align-self-center">{rating}</p>
            <p className="infoText my-sm-0 d-flex align-self-center">A.M Best Rating</p>
          </div>

          <CoverageOptions 
            class="col-12 col-md-6 d-flex justify-content-center align-items-center text-center mx-auto mx-sm-0 mt-sm-1 justify-content-sm-start px-0 justify-content-md-start d-md-none" 
            labelClass="featuredCovOptions"
            face_amount_options={face_amount_options}
          />

          <div className="col-12 px-sm-0 mt-1">
              {plan_type === 'Guaranteed Acceptance' ? (
                <p className={`benefit-text text-center infoText text-sm-left text-mdxl-left ${unique ? "" : "text-md-center text-lg-center"}`}>
                  2-year waiting period
                </p>
              ) : (
                <p className={`benefit-text text-center infoText text-sm-left text-mdxl-left ${unique ? "" : "text-md-center text-lg-center"}`}>No waiting period upon approval</p>
              )}
          </div>
        </div>

        <Divider parentClass="quoteCard order-sm-3 order-md-3" />

        <div className={`col-12 order-sm-4 px-0 d-sm-flex align-items-md-start`}>
          <div className={`d-sm-flex w-100 justify-content-sm-between flex-mdxl-row ${unique ? "" : "flex-md-column flex-lg-column justify-content-lg-center"} align-items-md-center justify-content-mdxl-between`}>
            <DownloadPolicy
                 policy_pdf_path={policy_pdf_path}
                 carrier={name}
                 class={`col-xs-12 col-sm-6 col-mdxl-6 d-flex align-items-center justify-content-center text-center cPointer mx-auto downloadContainer px-0 justify-content-sm-start mx-md-0 justify-content-mdxl-start ${unique ? "" : "justify-content-md-center col-md-12 col-lg-12 justify-content-lg-center"}`}
            />

            <div className={`px-0 d-flex col-12 col-sm-6 justify-content-center justify-content-sm-end ${unique ? "" : "col-md-3 justify-content-lg-center col-lg-12"}`}>
              <p
                className={`quoteBtn mt-sm-0 mt-mdxl-0 ${unique ? "" : "mt-md-4 mt-lg-4"} mt-4 fw-600`}
                onClick={props.saveQuote.bind(this, props.quote)}
              >
                Apply
              </p>
            </div>
          </div> 
        </div>
      </div>
      </div>
    </div>
  );
};
