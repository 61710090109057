import React from 'react';
import './QuizQuestion.css';
import QuoterDropdown from '../../../../common/QuoterDropdown/QuoterDropdown';

const QuizAgeSelect = props => {
  const ages = Array.from({ length: 18 }, (_, index) => ({
    label: String(index),
    value: String(index),
  }));

  return (
    <div>
      <div className='w-100 mt-gutter-y quizCoverageSelect'>
        <QuoterDropdown 
          list={ages}
          title="Age" 
          onChange={props.handleOption} 
          name="childAge" 
          selected={props.childAge ? {value: props.childAge.toString()} : null} 
          titleBold 
        />
      </div>
        <div className="errWrapper">
          <small className="error">{props.error_date_of_birth}</small>
        </div>
     </div>
  );
};

export default QuizAgeSelect;
