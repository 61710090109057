import React, { useState, useRef, useEffect } from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';

const Footer = (props) => {
  const [height, setHeight] = useState("0px");
  const ref = useRef();

  useEffect(() => {
    window.addEventListener(
      "message",
      (ev: MessageEvent<{ type: string; message: string }>) => {
        if (typeof ev.data !== "object") return;
        if (!ev.data.type) return;
        if (ev.data.type !== "footer-height") return;
        if (!ev.data.message) return;
        setHeight(ev.data.message);
      }
    );
  }, []);

  return (
    <footer className={props.loading ? "d-none":""} style={{height: height}}>
      <iframe
        ref={ref}
        id="myFrame"
        src={process.env.REACT_APP_FOOTER_URL}
        width="100%"
        height={height}
        scrolling="no"
        frameBorder="0"
        style={{
          width: "100%",
          overflow: "none",
          marginBottom: "-5px"
        }}
      ></iframe>
    </footer>
  );
};

const mapStateToProps  = (state) => ({
  loading: state.consumer.loading
})

export default compose(
  connect(mapStateToProps))(Footer);

