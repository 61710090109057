import React from 'react';
import './QuizQuestion.css';
import QuoterDropdown from '../../../../common/QuoterDropdown/QuoterDropdown';

const QuizStateSelect = props => {
  return (
    <div className="w-100 mt-gutter-y quizStateSelect">
        <QuoterDropdown 
          list={props.states.map(s=> {return {label: s.name, value: s.name}})} 
          title="State" 
          onChange={props.handleOption} name="livingState"
          selected={props.livingState ? {value: props.livingState} : null}
         />
        <div className="errWrapper">
          <small className="error quizErr d-block">{props.error_state}</small>
        </div>
  </div>
  );
};

QuizStateSelect.propTypes = {
};

export default QuizStateSelect;
