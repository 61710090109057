import React from 'react';
import termClock from '../../../../assets/Quotes/termClock.svg';
import termRise from '../../../../assets/Quotes/termRise.svg';
import easyIcon from '../../../../assets/Quotes/easyIcon.svg';
import Clock from '../../../../assets/Quotes/clock.svg';
import GIcon from '../../../../assets/Quotes/giIcon.svg';
import CPUnitsEquation from '../CPUnitsEquation/CPUnitsEquation';
import { isChrome } from '../../../../utils/Browsers';
import { isWindows } from '../../../../utils/isWindows';

const PlanType = props => {
    const {insurance_amount_st, plan_key, product_type, plan_type, parentClassName, wrapperClassName, secondParentClassName, notAvailable, colonialPenn, metaInfo} = props

  return (
    <div className={`mx-auto mx-sm-0 col-12 ${colonialPenn ? "col-sm-7" : "col-sm-6" } px-0 ${parentClassName}`}>
        <div className={`mt-3 px-0 planTypeWrapper ${secondParentClassName}`}>
         {product_type == "term_life" ? 
          <div className={`d-flex flex-column align-items-center justify-content-center px-0 planType align-items-sm-end ${wrapperClassName}`}>
              <div className="mr-sm-4">
                <img src={termClock} alt="icon" className="icon" />
                <span className="infoText colorText">Expires at age 80</span>
              </div>
              <div>
                <img src={termRise} alt="icon" className="icon" />
                <span className="infoText colorText">Price rises every 5 years</span>
              </div>
          </div>
         : plan_key == "gtl" ? 
          <div className={`d-flex flex-column align-items-center align-items-sm-end justify-content-center col-md-12 px-0 planType ${wrapperClassName}`}>
              <div className="d-flex mr-sm-2">
                <img src={easyIcon} alt="icon" className="icon" />
                <h5 className="easy fw-600">Easy Acceptance<br /></h5>
              </div>
              <span className="infoText colorText text-center text-md-left text-mdxl-center text-lg-left">1-year waiting period then<br /> 50% payout in 2nd year</span>
          </div>
         : plan_type === 'Guaranteed Acceptance' ? (
           <div className={`d-flex flex-column align-items-center justify-content-center px-0 planType align-items-sm-end ${wrapperClassName}`}>
             <div className="d-flex">
                <img src={GIcon} alt="icon" className="icon" />
                <h5 className="guaranteed fs-15 fw-600">Guaranteed Acceptance<br /></h5>
             </div>
              <span className="infoText colorText mr-sm-4">2-year waiting period</span>
           </div>
         ) : (
           <div className={`d-flex flex-column align-items-center justify-content-center px-0 planType align-items-sm-end ${wrapperClassName}`}>
             <div className="d-flex mr-sm-3">
              <img src={Clock} alt="icon" className="icon" />
              <h5 className="immediate fs-15 fw-600">Immediate Coverage<br /></h5>
             </div>
             <span className="infoText colorText">No waiting period on approval</span>
           </div>
         )}
        </div>

        {!notAvailable && <div className={`d-none d-sm-flex smPrice align-items-center justify-content-end d-md-none d-mdxl-flex d-lg-none ${plan_key == "gtl" ? "gtlPrice":""} ${colonialPenn && "cpSmPrice"}`}>
          <div className="d-flex text-nowrap h-34">
            <h3 className="fs-26 lh-34">${insurance_amount_st}</h3>
            <p className={`mb-0 pl2 fs-18 ${isChrome() && isWindows() ? "lh-40" : "lh-38"}`}> /mo</p>
            {colonialPenn && metaInfo && <span className={`fs-15 deepGray ml-2 ${isChrome() && isWindows() ? "lh-40" : "lh-38"}`}>({metaInfo.unit_cost} per unit)</span>}
          </div>
        </div>}

        {colonialPenn && metaInfo && !notAvailable && <CPUnitsEquation metaInfo={metaInfo} className="d-none d-sm-block justify-content-sm-end d-md-none d-mdxl-block d-lg-none" />}
  </div>
  );
};

export default PlanType;
