import React, { useState, useEffect } from 'react';
import { isMacOS } from '../../../utils/isMacOS';
import { isFirefox } from '../../../utils/isFirefox';
import ExitIntentCMLogoPng from '../../../assets/ExitIntentPopup/ExitIntentCMLogo.png';
import ExitIntentCMLogoSvg from '../../../assets/ExitIntentPopup/ExitIntentCMLogo.svg';
import VectorPng from '../../../assets/ExitIntentPopup/Vector.png';
import VectorSvg from '../../../assets/ExitIntentPopup/Vector.svg';
import ExitIntentMaskGroup from '../../../assets/ExitIntentPopup/ExitIntent_MaskGroup.png';

const ExitIntentPopup = ({ showPopup: externalShowPopup, mobileTriggers, agent_phone_number }) => {
    const [showPopup, setShowPopup] = useState(externalShowPopup);
    const [closed, setClosed] = useState(false);
    const idleTime = 20000;
    let idleTimeout;

  useEffect(() => {
 
    const handleMobileBackButton = () => {
      setShowPopup(true);
    };

    const handleIdleTimeout = () => {
      setShowPopup(true);
    };

    const handleMouseLeave = (event) => {
        // Check if the mouse leaves from the top or the bottom of the window
        if (event.clientY <= 0 || event.clientY >= window.innerHeight) {
          setShowPopup(true);
          document.body.classList.add('no-scroll'); // Add no-scroll class to body when popup is shown
        }
      };
  
    const handleMouseEnter = () => {
    };

    const handleUserActivity = () => {
      // Reset the idle timeout
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(handleIdleTimeout, idleTime);
    };
  
    const handleFocus = () => {
      // Window gained focus (tab is active)
      handleUserActivity();
    };
    
    const handleBlur = () => {
      // Window lost focus (tab is inactive)
      setShowPopup(true); // Show the popup when the window loses focus
    };
    

    if (mobileTriggers()) {
      window.addEventListener('popstate', handleMobileBackButton);
      window.addEventListener('focus', handleFocus);
      window.addEventListener('blur', handleBlur);
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);  
      window.addEventListener('touchmove', handleUserActivity);

      // Start the idle timeout
      idleTimeout = setTimeout(handleIdleTimeout, idleTime);

      return () => {
        window.removeEventListener('popstate', handleMobileBackButton);
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchmove', handleUserActivity);
        clearTimeout(idleTimeout);
        document.body.classList.remove('no-scroll'); // Remove no-scroll class when component unmounts
      };
    } else {
        document.addEventListener('mouseout', handleMouseLeave);
        document.addEventListener('mouseenter', handleMouseEnter);
    
        return () => {
          document.addEventListener('mouseout', handleMouseLeave);
          document.removeEventListener('mouseenter', handleMouseEnter);
          document.body.classList.remove('no-scroll'); // Remove no-scroll class when component unmounts
        };
    }
  }, [externalShowPopup]);

  useEffect(() => {
    if(!closed) {
      setShowPopup(externalShowPopup);
      if (externalShowPopup) {
        document.body.classList.add('no-scroll'); // Add no-scroll class to body when popup is shown
      } else {
        document.body.classList.remove('no-scroll'); // Remove no-scroll class from body when popup is hidden
      }
    }
  }, [externalShowPopup]);

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.classList.remove('no-scroll'); // Remove no-scroll class from body when popup is hidden
    setClosed(true);
  };

  // Function to convert phone number format
  const formatPhoneNumber = (phoneNumber) => {
      // Remove all non-numeric characters
      const cleaned = phoneNumber.replace(/\D/g, '');
      
      // Check if the cleaned number has the expected length
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      
      // If there's a match, return the formatted phone number; otherwise, return the original number
      return match ? `${match[1]}-${match[2]}-${match[3]}` : phoneNumber;
  };

  return (
    showPopup && !closed && <div className="exitIntentPopup w-100 h-100">
        <div className="exitIntentWrapper">
          <img className="d-none exitIntentDesktopBackground" src={ExitIntentMaskGroup} />
          <div className='exitIntentPopupBody'>
            <img onClick={handleClosePopup} className='closeCross c-pointer' src={ !isMacOS() && isFirefox() ?  VectorPng : VectorSvg} />
            <h2>Did you add {agent_phone_number ? formatPhoneNumber(agent_phone_number) : "775-571-1290"} to your contacts?</h2>
            <br />
            <h5>Most phones automatically block calls from unknown numbers, so it is crucial that you add our phone number to your contacts to guarantee you receive our call.</h5>
            <br />
            <h5>We will cancel your application if we can’t verbally speak with via telephone.</h5>
            <br />
            <div className='d-flex justify-content-between'>
              <h5>Talk to you soon.</h5>
              <img className="exitIntentCMLogo" src={ !isMacOS() && isFirefox() ?  ExitIntentCMLogoPng : ExitIntentCMLogoSvg} />
            </div>
          </div>
        </div>
    </div>
  );
};

export default ExitIntentPopup;