import React from 'react';
import PropTypes from 'prop-types';
import './Titles.css';

const Titles = props => {
  const { title, subtitle, containerClass } = props;
  return (
    <div className={`titles titleBody mt-md-0 col-12 px-0 ${containerClass}`}>
      <h2 className="mb-0 mt-3">{title}</h2>
      <p className="mb-0 mt-2">{subtitle}</p>
    </div>
  );
};

Titles.defaultProps = {
  title: 'Please define a title',
  subtitle: 'Please define a subtitle'
};

Titles.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
};

export default Titles;
