import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Cross from '../../../../assets/Modals/roundedCross.svg'
import Vector from '../../../../assets/Quotes/vector.svg'
import Dot from '../../../../assets/Quotes/dot.svg'
import "./PlanDescriptionModal.css"

const PlanDescriptionModal = props => {
  return (
    <Modal
      isOpen={props.planModal}
      toggle={props.togglePlanModal}
      className={props.className}
    >
      <div className="d-flex align-items-center text-center justify-content-center">
        <img className="modalCross cPointer" onClick={props.togglePlanModal} src={Cross} />
      </div>
      <ModalBody className="d-flex">
          {props.productType == "final_expense" ? 
            <div>
              <h2 className="fs-18">Whole Life Insurance</h2>
              <div className="fs-15 mt-3">These policies always offer the following benefits:</div>
              <div className="fs-15 mt-1">
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Vector}></img>
                  Premiums never increase
                </div>
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Vector}></img>
                  Coverage never decreases
                </div>
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Vector}></img>
                  Policy never expires
                </div>
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Vector}></img>
                  No medical exam to qualify
                </div>
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Vector}></img>
                  No money due upfront
                </div>
              </div>
              <div className="fs-15 mt-2"><span className="fw-500">Guaranteed Issue</span> plans require a 2-year waiting period for the full benefit to be payable.</div>
              <div className="fs-15 mt-2"><span className="fw-500">Immediate Coverage</span> plans do not involve any waiting period for coverage to begin.</div>
            </div> : 
            <div>
              <h2 className="fs-18">Term Life Insurance</h2>
              <div className="fs-15 mt-3">These policies always include the following features:</div>
              <div className="fs-15 mt-1">
                <div className="d-flex align-items-center">
                  <img className="mr-2" src={Dot}></img>
                  Price rises every 5 years
                </div>
                <div className="d-flex align-items-center mt-1">
                  <img className="mr-2" src={Dot}></img>
                  Expires at age 80 or 90
                </div>
                <div className="d-flex align-items-center mt-1">
                  <img className="mr-2" src={Dot}></img>
                  No medical exam to qualify
                </div>
              </div>
              <div className="fs-15 mt-2">This type of policy provides temporary coverage that expires at age 80 or 90. Once it expires, you no longer have insurance, and you don't get your money back. In addition, the monthly premium will increase every five (5) years.</div>
              <div className="fs-15 mt-2">Term life is always cheaper than whole life because term policies expire and the price rises over time. In contrast, whole life lasts forever and has a locked-in premium.</div>
            </div>}
      </ModalBody>
    </Modal>
  );
};

export default PlanDescriptionModal;
