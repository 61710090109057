import React from 'react';
import PropTypes from 'prop-types';
import './QuizQuestion.css';
import QuizTwoOptionsSelect from './QuizTwoOptionsSelect';
import QuizDateSelect from './QuizDateSelect';
import QuizStateSelect from './QuizStateSelect';
import QuizCoverageSelect from './QuizCoverageSelect';
import QuizContactInformation from './QuizContactInformation';
import QuizAgeSelect from './QuizAgeSelect';

const QuizMap = {
  "QuizTwoOptionsSelect": QuizTwoOptionsSelect,
  "QuizDateSelect": QuizDateSelect,
  "QuizStateSelect": QuizStateSelect,
  "QuizCoverageSelect": QuizCoverageSelect,
  "QuizContactInformation": QuizContactInformation, 
  "QuizAgeSelect": QuizAgeSelect,
}

const QuizQuestion = props => {
  const {text, type, description} = props.question
  let CurrentComponent = QuizMap[type];

  return (
    <div 
        className={`${props.questionI == props.currentQuestionI ? "d-flex" : "d-none"} 
                    quizQuestionBox flex-column align-items-center justify-content-center 
                    ${type == "QuizContactInformation" ? "contactInfoPad" : ""} question${props.questionI} 
                    ${props.prevQuestionI != undefined && props.prevQuestionI < props.currentQuestionI ? "slideNextQAppearence" : props.prevQuestionI != undefined ? "slidePrevQAppearence":""}`}
     >
        <div className="d-flex flex-column align-items-center text-center">
          <h4 className={`${props.question.textClass}`} dangerouslySetInnerHTML={{ __html: text }}></h4>
          {description && <div className="fs-15 mw-400">{description}</div>}
        </div>
        <div className='w-100 mt-3'>
          <CurrentComponent 
            {...props.question} 
            {...props}
          />
        </div>
    </div>
  );
};

QuizQuestion.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
};

export default QuizQuestion;
