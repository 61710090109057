import React from 'react';
import EditInfoLink from '../../../../common/Buttons/components/EditInfoLink';
import BackButton from '../../../../common/Buttons/components/BackButton';
import noQuoteImg from '../../../../assets/Quotes/noQuotes.svg';
import './NoQuotesLayout.css'

const NoQuotesLayout = (props) => {
  let highQuotesEnabled = props.highQuotesEnabled == 'true'
  return (
    <div className="no-quotes col-12 px-0">
      <div className="noQuotesTitle d-flex align-items-center justify-content-center text-center px-2">
        <h2 className="my-auto">Sorry, no quotes are available.</h2>
      </div>
      <div className="content">
        <figure className="text-center">
          <img src={noQuoteImg} alt="no quotes" />
        </figure>
        <p>
          Unfortunately, we cannot generate quotes based on the information you provided. This might be because of the face amount you selected, your age, your state, or a combination of these factors.
        </p>
        <br />
        <p>
          We appreciate your interest in our products. If there are any amendments you’d like to suggest, please let us know! You can reach us at <strong>800-644-2926.</strong>
        </p>
        <div className="row buttonsWrapper px-0 mx-0">
          <div className="back-btn-wrapper col-12 col-sm-7 order-sm-2 px-0">
            <BackButton route="//choicemutual.com" text="Return to Choice Mutual" />
          </div>
          <div className="edit-btn col-12 col-sm-5 px-0 d-sm-flex justify-content-md-end editInfoWrapper">
            <EditInfoLink route={highQuotesEnabled ? '/?high_quotes_enabled=true' : '/'} text="Edit Application" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoQuotesLayout;
