import React from 'react';
import { numberAsCurrency } from '../../utils/CurrencyHelper';
import QuoterDropdown from '../../common/QuoterDropdown/QuoterDropdown';
import { getAvailableCoverages } from '../../utils/CoverageHelper'

const CoverageSelect = props => {
  const { livingState, years, onChange, coverage, highQuotesEnabled, childrenApplication } = props;

  const coverages = getAvailableCoverages(livingState, years, highQuotesEnabled, childrenApplication);
  sessionStorage.setItem("availableCoverages", JSON.stringify(coverages));

  return (
    <div className="col-12 px-0">
      <QuoterDropdown 
        list={coverages.map(c=> {return {label: numberAsCurrency(c), value:c.toString()}})} 
        title="Coverage" 
        onChange={onChange} name="coverage"
        selected={coverage ? {value: coverage.toString(), label: numberAsCurrency(coverage)} : null}
        scrollTo={childrenApplication ? null : 7000}
       />
    </div>
  );
};

export default CoverageSelect;
