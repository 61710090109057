import React from 'react';
import './CMTooltip.css'

const CMTooltip = props => {
  return (
    <div className="CMTooltipWrapper fs-15">
        {props.content}
    </div>
  );
};

export default CMTooltip;
