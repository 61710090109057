import React from 'react';
import 'core-js/es6/symbol';
import 'core-js/fn/symbol/iterator';

const ApplicationForm = props => {
  return (
    <form
      className="needs-validation"
      onSubmit={props.saveApplication}
      noValidate
      autoComplete="off"
    >
      <div className="d-flex flex-column form" id="insuring">
        <h5>Your Contact Information</h5>
        <div className="input-container my-2">
          <input
            className="form-control infoText"
            type="text"
            name="insured_name"
            id="insured_name"
            defaultValue={props.insured_name}
            onBlur={props.handleBlur}
            required
          />
          <label className={`infoText ${props.insured_name && 'filled'}`}>Name</label>
          <small className="invalid-feedback">This field is required</small>
        </div>

        <div className="input-container my-2">
          <input
            className="form-control infoText"
            type="tel"
            name="mainPhone"
            id="mainPhone"
            maxLength="14"
            required
            defaultValue={props.mainPhone}
            onBlur={props.handleBlur}
            onKeyUp={props.validateNumber}
          />
          <label className={`infoText ${props.mainPhone && 'filled'}`}>Phone Number</label>
          <small className="invalid-feedback">This field is required</small>
        </div>

        <div className="input-container my-2">
          <input
            className="form-control infoText"
            type="tel"
            maxLength="14"
            name="alternatePhone"
            id="alternatePhone"
            defaultValue={props.alternatePhone}
            onBlur={props.handleBlur}
            onKeyUp={props.validateNumber}
          />
          <label className={`infoText ${props.alternatePhone && 'filled'}`}>Alternate Phone Number (optional)</label>
        </div>

        <div className="input-container my-2">
          <input
            className="form-control infoText"
            type="email"
            name="email"
            id="email"
            defaultValue={props.email}
            onBlur={props.handleBlur}
            required
          />
          <label className={`infoText ${props.email && 'filled'}`}>Email</label>
          <small className="invalid-feedback">This field is required</small>
        </div>
        {props.error ? (
          <div className="col-12 text-center alert alert-danger py-3 mt-3">
            {props.error}
          </div>
        ) : null}

        <button className="submit-btn btn btn-block" type="submit">Submit Application</button>

        <div className="disclaimer font-weight-bold">We do not share your information with anyone. Only 1 Choice Mutual agent will call you. Your insurance application is not final until we speak with you via phone.</div>
        <div className='fs-generalinfo-disclaimer mt-2'>By pressing "Submit Application" you agree to our privacy policy and consent to have a Choice 
             Mutual agent contact you by email, phone call, text/SMS message at the phone number and 
             email you provide. Consent isn’t a condition to purchase our products.</div>
      </div>
    </form>
  );
};

export default ApplicationForm;
