import React, { useState, useEffect } from 'react';
import arrow from '../../../assets/arrow.svg'
import arrowPng from '../../../assets/arrowGreen.png'
import { capitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter'
import { numberAsCurrency } from '../../../utils/CurrencyHelper'
import { getStateAbbreviation } from '../../../utils/StateAbbreviation'
import './QuizBarLayout.css'
import moment from 'moment';
import { isMacOS } from '../../../utils/isMacOS'
import { isFirefox } from '../../../utils/isFirefox'

const QuizItem = props => {
  return (
    <div id={props.id} onClick={props.handleClick} className={`${!isMacOS() && "not-mac-os"} ${props.isCheckededQuestion(props.currentQuestionI, props.questionPosition) && "checked-question"} ${props.isCurrentQuestion(props.currentQuestionI, props.questionPosition) && "current-question"} ${props.class}`}>
      { props.isCheckededQuestion(props.currentQuestionI, props.questionPosition) && props.text }
    </div>
  )
}

const QuizItemResponse = props => {
  return (
    <div className={`${props.class} ${props.response && props.isCheckeded ? "fade-in": "fade-out"}`}>
      { props.response }
    </div>
  )
}

const FormIndicatorLayout = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let ageText = windowWidth >= 480 ? "DATE OF BIRTH": "AGE"
  let progressBarSelectionWidth = props.childrenApplication ? 33 : 20;
  let progressWidth = (props.currentQuestionI + 1) * progressBarSelectionWidth
  
  /* Specific progress bar width */
  if(!props.childrenApplication) {
    if ((props.currentQuestionI == 1 || props.currentQuestionI == 2) && windowWidth < 480){
      progressWidth = progressWidth - (5 * props.currentQuestionI);
    }
    if ((props.currentQuestionI == 3) && windowWidth < 480){
      progressWidth = progressWidth - 6;
    }
    if ((props.currentQuestionI == 1 ) && windowWidth >= 480){
      progressWidth = progressWidth + 5;
    }
  }

  /* Responses handling */

  const birthDate = moment();
  birthDate.set('year', parseInt(props.year, 10));
  birthDate.set('month', parseInt(props.month - 1, 10));
  birthDate.set('date', parseInt(props.day, 10));
  let responseDob =  windowWidth >= 480 && props.month && props.day && props.year ? `${birthDate.format('MM/DD/YYYY')}` : props.years ? props.years : ''
  let responseGender = capitalizeFirstLetter(props.gender)
  let responseState = getStateAbbreviation(props.states, props.livingState)
  let responseTobacco = props.smoked == 'no' ? "Non-User" : props.smoked == 'yes' ? "User" : ''
  let responseCoverage = props.coverage ? numberAsCurrency(props.coverage) : ''
  let responseChildAge = props.childAge

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className={`form-indicator mx-auto ${props.childrenApplication ? "childrenApplication" : ""}`}>
      <div className="bar-frame p-relative">
        <div className="progress-wrapper">
          <div className="progress" style={{ width: `${progressWidth}%`}}>
          </div>
          <img src={ !isMacOS() && isFirefox() ? arrowPng : arrow }/>
        </div>
        {props.childrenApplication ?  
          <div className="items-wrapper justify-content-between">
            <QuizItem {...props} questionPosition={0} text='YOUR STATE' id='state-question-indicator' class='state'/>
            <QuizItem {...props} questionPosition={1} text='COVERAGE' id='coverage-question-indicator' class='coverage'/>
            <QuizItem {...props} questionPosition={2} text="CHILD'S AGE" id='age-question-indicator' class='age'/>
          </div>
          :<div className="items-wrapper justify-content-between">
            <QuizItem {...props} questionPosition={0} text='GENDER' id='gender-question-indicator' class='gender'/>
            <QuizItem {...props} questionPosition={1} text={ageText} id='age-question-indicator' class='age'/>
            <QuizItem {...props} questionPosition={2} text='STATE' id='state-question-indicator' class='state'/>
            <QuizItem {...props} questionPosition={3} text='TOBACCO' id='tobacco-question-indicator' class='tobacco'/>
            <QuizItem {...props} questionPosition={4} text='COVERAGE' id='coverage-question-indicator' class='coverage'/>
          </div>
        }
      </div>
      {
        props.childrenApplication ?
        <div className="form-responses d-flex justify-content-between">
          <QuizItemResponse {...props} class="response-state" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 0)} response={responseState}/>
          <QuizItemResponse {...props} class="response-coverage" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 1)} response={responseCoverage}/>
          <QuizItemResponse {...props} class="response-age" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 2)} response={responseChildAge}/>
        </div>
        :
        <div className="form-responses d-flex justify-content-between">
          <QuizItemResponse {...props} class="response-gender" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 0)} response={responseGender}/>
          <QuizItemResponse {...props} class="response-age" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 1)} response={responseDob}/>
          <QuizItemResponse {...props} class="response-state" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 2)} response={responseState}/>
          <QuizItemResponse {...props} class="response-tobacco" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 3)} response={responseTobacco}/>
          <QuizItemResponse {...props} class="response-coverage" isCheckeded={props.isCheckededQuestion(props.currentQuestionI, 4)} response={responseCoverage}/>
        </div>
      }
    </div>
  );
};

export default FormIndicatorLayout;
