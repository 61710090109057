import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../../common/ProgressBar/containers/ProgressBar';
import './DetailsLayout.css';
import TrustedInsurers from './TrustedInsurers';
import QuizBar from './QuizBar';
import QuizQuestion from './Quiz/QuizQuestion';
import QuizNav from '../../../common/QuizNav/QuizNav';

const DetailsLayout = props => {
  const isFirstQuestion =  props.currentQuestionI == 0;
  const showPrevious = !isFirstQuestion;
  const isContactInfo = props.currentQuestion.type === "QuizContactInformation";
  const goingBackToOptions = props.currentQuestion.type === "QuizTwoOptionsSelect" && props.enableContinueForTwoOptions;
  const showContinue = (!isContactInfo && props.currentQuestion.type !== "QuizTwoOptionsSelect") || goingBackToOptions || (props.currentQuestion.type == "QuizTwoOptionsSelect" && !isFirstQuestion);

  return (
    <section className="details">
      <div className="progressBarWrapper d-md-none d-flex align-items-center content generalInformation">
        <ProgressBar />
      </div>

      <div className='blobWrapper'>
        <div className="mx-0 c-quoting-blob py-6 content">
          <h1 className='title text-center'>Final Expense Insurance Quotes Calculator</h1>
          <QuizBar {...props}/>
          {
            props.questions.map((question, i)=> 
              <QuizQuestion 
                key={`quizQuestionKey${i}`}
                {...props}
                question={question}
                questionI={i}
              />
           )
          }
          <QuizNav 
            showPrevious={showPrevious} 
            showContinue={showContinue}
            previousQuestion={props.previousQuestion}
            currentValueFullfilled={props.currentValueFullfilled}
            currentQuestionI={props.currentQuestionI}
            nextQuestion={props.nextQuestion}
          />
          <TrustedInsurers />
        </div>
      </div>


      <p className="fields-required text-danger">{props.errorMsg}</p>
    </section>
  );
};

DetailsLayout.propTypes = {
  handleOption: PropTypes.func.isRequired,
  toggleGenderSelect: PropTypes.func.isRequired,
  toggleSmokeSelect: PropTypes.func.isRequired,
  handleDetailsSubmit: PropTypes.func.isRequired
};

export default DetailsLayout;
