import React from 'react';
import CoverageOptions from './CoverageOptions/CoverageOptions';
import Divider from '../../Common/Divider'
import DownloadPolicy from './DownloadPolicy/DownloadPolicy';
import CarrierLogo from '../../../utils/CarrierLogo';
import PlanType from './PlanType/PlanType';
import NotAvailable from './NotAvailable/NotAvailable';
import CPUnitsEquation from './CPUnitsEquation/CPUnitsEquation';
import { isChrome } from '../../../utils/Browsers';
import { isWindows } from '../../../utils/isWindows';
import CMTooltip from './CMTooltip/CMTooltip';

const Quote = props => {
  const { name, rating, insurance_amount_st, plan_type, face_amount_options, 
          policy_pdf_path, key, product_type, reason, age_availability, meta_info } = props.quote;
  const { notAvailable, livingState, age, openCoverageInfoModal, closeCoverageInfoModal, term_life, index, coverageTooltipKey, setCoverageTooltip, coverageTooltipContent } = props
  const colonialPenn = key === "colonial_penn_guaranteed_issue_whole_life";

  return (
    <div className={`quote col-xs-12 d-flex myQuote quoteCard ${notAvailable ? "notAvailable" : ""} ${term_life && index == 0 ? "mt-0" : "" } p-relative`}>
      <div className={`mx-auto order-sm-1 mx-sm-0 col-12 ${colonialPenn ? "col-sm-5 col-mdxl-5" : "col-sm-6 col-mdxl-6 wleftcol-sm-override" } px-0 order-md-1 d-md-flex col-md-12 justify-content-md-between order-mdxl-1 col-lg-12 justify-content-lg-between`}>
        <div className="d-flex flex-column mx-auto mx-sm-0 flex-md-row flex-mdxl-column flex-lg-row d-md-7 px-md-0">
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-start px-0 mb-sm-1">
             {props.isMobile() ? (
               <CarrierLogo
                 className={`${name.split(" ").join("")} logo mx-sm-0`}
                 name={name}
               />
             ) : (
               <CarrierLogo className={`${name.split(" ").join("")} logo`} name={name}/>
             )}
          </div>

          <div className="d-flex justify-content-center justify-content-sm-start flex-md-column ml-md-3 ml-mdxl-0 ml-lg-3">
            <h5 className='mt-2 mx-auto mx-sm-0 fs-18 mt-md-0 mt-mdxl-2 mt-lg-0 fw-600'>
                {name}
            </h5>

            <div className="d-none align-items-center ratingWrapper mx-auto mx-sm-0 px-sm-0 mt-3 mt-md-1 mt-sm-2 justify-content-center justify-content-sm-start d-md-flex">
              <p className="raiting navSecondaryItems mr4 my-sm-0 d-flex align-self-center">{rating}</p>
              <p className="infoText my-sm-0 d-flex align-self-center" >A.M Best Rating</p>
            </div>

            <CoverageOptions 
              face_amount_options={face_amount_options} 
              class="col-12 d-none justify-content-center align-items-center align-items-md-start text-center mx-auto mx-sm-0 justify-content-sm-start px-0 justify-content-md-start d-md-flex mt-md-1" 
              labelClass="featuredCovOptions"
            />
          </div>
        </div>

        {!notAvailable && <div className="col-12 col-md-5 priceWrapper mt-1 d-sm-none d-md-flex px-md-0 justify-content-md-end mt-md-0 d-mdxl-none d-lg-flex px-lg-0 justify-content-lg-end mt-lg-0 col-lg-5 col-lgxl-4 flex-wrap">
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex text-nowrap h-34">
              <h3 className="fs-26 lh-34">${insurance_amount_st}</h3>
              <p className={`mb-0 pl2 fs-18 ${isChrome() && isWindows() ? "lh-40" : "lh-38"}`}> /mo</p>
              {colonialPenn && meta_info && <span className={`fs-15 ml-2 ${isChrome() && isWindows() ? "lh-40" : "lh-38"} deepGray`}>({meta_info.unit_cost} per unit)</span>}
            </div>
          </div>
          {colonialPenn && meta_info && <CPUnitsEquation metaInfo={meta_info} className="d-sm-none d-md-flex justify-content-md-end" />}
        </div>}


        <div className="d-flex align-items-center ratingWrapper mx-auto mx-sm-0 px-sm-0 mt-3 mt-sm-1 justify-content-center justify-content-sm-start d-md-none">
          <p className="raiting navSecondaryItems mr4 my-sm-0 d-flex align-self-center">{rating}</p>
          <p className="infoText my-sm-0 d-flex align-self-center">A.M Best Rating</p>
        </div>

        <CoverageOptions 
          face_amount_options={face_amount_options} 
          class={`col-12 col-md-6 d-flex mb-1 mb-sm-0 mt-sm-1 justify-content-center align-items-center text-center mx-auto mx-sm-0 justify-content-sm-start px-0 justify-content-md-start d-md-none ${key == "aarp_increasing_premium_term_life" ? "AarpTLCovRange" : ""}`}
          labelClass="featuredCovOptions"
        />
      </div>

      <PlanType 
        parentClassName={`order-sm-2 d-md-none d-mdxl-flex flex-mdxl-column order-mdxl-2 d-lg-none ${colonialPenn ? "":"wrightcol-sm-override"}`}
        insurance_amount_st={insurance_amount_st} 
        plan_key={key} 
        product_type={product_type} 
        plan_type={plan_type}
        notAvailable={notAvailable}
        metaInfo={meta_info}
        colonialPenn={colonialPenn} 
      />

      <Divider parentClass="quoteCard order-sm-3 order-md-3" />

      <div className={`col-12 order-sm-4 px-0 d-sm-flex ${notAvailable ? "justify-content-between" : ""} p-relative`}>
        <PlanType 
          parentClassName="d-none d-md-flex align-items-md-center col-md-5 d-mdxl-none d-lg-flex align-items-lg-center" 
          secondParentClassName="mt-md-0"
          wrapperClassName="align-items-md-start"
          insurance_amount_st={insurance_amount_st} 
          plan_key={key} 
          product_type={product_type} 
          plan_type={plan_type} 
          metaInfo={meta_info}
          colonialPenn={colonialPenn}
        />

        {notAvailable ? 
          <NotAvailable 
            planKey={key}
            reason={reason}
            state={livingState}
            ageAvailability={age_availability}
            age={age}
            metaInfo={meta_info}
            openCoverageInfoModal={openCoverageInfoModal}
            closeCoverageInfoModal={closeCoverageInfoModal}
            colonialPenn={colonialPenn} 
            setCoverageTooltip={setCoverageTooltip}
          />
        :
        <div className={`d-sm-flex w-100 ${ policy_pdf_path ? "justify-content-sm-between justify-content-mdxl-between": "justify-content-sm-end "} justify-content-md-end justify-content-lg-end`}>
          {policy_pdf_path && <DownloadPolicy
               policy_pdf_path={policy_pdf_path}
               carrier={name}
               class="col-xs-12 col-sm-6 col-md-8 col-mdxl-6 col-lg-8 d-flex align-items-center justify-content-center text-center cPointer mx-auto downloadContainer px-0 justify-content-sm-start justify-content-md-center mx-md-0 justify-content-mdxl-start justify-content-lg-center"
          />}

          <div className="px-0 d-flex col-12 col-sm-6 justify-content-center justify-content-sm-end col-md-3">
            <p
              className={`quoteBtn ${policy_pdf_path ? "mt-4 mt-sm-0" : ""} fw-600`}
              onClick={props.saveQuote.bind(this, props.quote)}
            >
              Apply
            </p>
          </div>
        </div> }
        {coverageTooltipKey === key && <CMTooltip content={coverageTooltipContent} />}
      </div>
    </div>
  );
};

export default Quote;
