import React from 'react';
import moo from '../assets/SquareLogos/Mutual of Omaha.svg';
import aetna from '../assets/SquareLogos/Aetna.svg';
import lbl from '../assets/SquareLogos/Liberty Bankers.svg';
import prosperity from '../assets/SquareLogos/Prosperity Life.svg';
import foresters from '../assets/SquareLogos/Foresters.svg';
import baltimore from '../assets/SquareLogos/Baltimore Life.svg';
import american from '../assets/SquareLogos/American Amicable.svg';
import ahl from '../assets/SquareLogos/American Home Life.svg';
import americo from '../assets/SquareLogos/Americo.svg';
import rna from '../assets/SquareLogos/Royal Neighbors.svg';
import gerber from '../assets/SquareLogos/Gerber Life.svg';
import aig from '../assets/SquareLogos/AIG.svg';
import great_western from '../assets/SquareLogos/Great Western.svg';
import cfg from '../assets/SquareLogos/Columbian Financial.svg';
import transamerica from '../assets/SquareLogos/Transamerica.svg';
import aaa from '../assets/SquareLogos/AAA.svg';
import aarp from '../assets/SquareLogos/AARP.svg';
import colonial_penn from '../assets/SquareLogos/Colonial Penn.svg';
import ethos from '../assets/Competitors/EthosLife_grey.svg';
import lincoln_heritage from '../assets/SquareLogos/Lincoln Heritage.svg';
import nfa from '../assets/SquareLogos/National Family Assurance.svg';
import trustage from '../assets/SquareLogos/Trustage.svg';
import aflac from '../assets/SquareLogos/Aflac.svg';
import fidelity_life from '../assets/SquareLogos/Fidelity Life.svg';
import globe_life from '../assets/SquareLogos/Globe Life.svg';
import gtl from '../assets/SquareLogos/GTL.svg';
import lumico from '../assets/SquareLogos/Lumico.svg';
import physicians_mutual from '../assets/SquareLogos/Physicians Mutual.svg';
import state_farm from '../assets/SquareLogos/State Farm.svg';
import usaa from '../assets/SquareLogos/USAA.svg';
import cica from '../assets/SquareLogos/CICA.svg';

const CarrierLogo = props => {
    switch (props.name) {
        case 'Aetna':
          return <img className={props.className} alt="carrier" src={aetna}/>;
        case 'Liberty Bankers Life':
          return <img className={props.className} alt="carrier" src={lbl}/>;
        case 'Prosperity Life Group':
          return <img className={props.className} alt="carrier" src={prosperity}/>;
        case 'Foresters Financial':
          return <img className={props.className} alt="carrier" src={foresters}/>;
        case 'Baltimore Life':
          return <img className={props.className} alt="carrier" src={baltimore}/>;
        case 'American Amicable':
          return <img className={props.className} alt="carrier" src={american}/>;
        case 'American Home Life':
          return <img className={props.className} alt="carrier" src={ahl}/>;
        case 'Americo':
         return <img className={props.className} alt="carrier" src={americo}/>;
        case 'Royal Neighbors Of America':
          return <img className={props.className} alt="carrier" src={rna}/>;
        case 'Gerber':
          return <img className={props.className} src={gerber} alt="carrier"/>
        case 'AIG':
          return <img className={props.className} alt="carrier" src={aig}/>;
        case 'Great Western':
          return <img className={props.className} alt="carrier" src={great_western}/>;
        case 'Columbian Financial Group':
          return <img className={props.className} alt="carrier" src={cfg}/>;
        case 'Transamerica':
          return <img className={props.className} alt="carrier" src={transamerica}/>;
        case 'AAA':
          return <img className={props.className} alt="carrier" src={aaa}/>;
        case 'AARP':
          return <img className={props.className} alt="carrier" src={aarp}/>;
        case 'Colonial Penn':
          return <img className={props.className} alt="carrier" src={colonial_penn}/>;
        case 'Ethos':
          return <img className={props.className} alt="carrier" src={ethos}/>;
        case 'Lincoln Heritage':
          return <img className={props.className} alt="carrier" src={lincoln_heritage}/>;
        case 'TruStage':
          return <img className={props.className} alt="carrier" src={trustage}/>;
        case 'Lumico':
          return <img className={props.className} alt="carrier" src={lumico}/>;
        case 'Aflac':
            return <img className={props.className} alt="carrier" src={aflac}/>;
        case 'Fidelity Life':
            return <img className={props.className} alt="carrier" src={fidelity_life}/>;
        case 'Globe Life':
            return <img className={props.className} alt="carrier" src={globe_life}/>;
        case 'Guarantee Trust Life':
            return <img className={props.className} alt="carrier" src={gtl}/>;
        case 'National Family':
            return <img className={props.className} alt="carrier" src={nfa}/>;
        case 'Physicians Mutual':
            return <img className={props.className} alt="carrier" src={physicians_mutual}/>;
        case 'State Farm':
            return <img className={props.className} alt="carrier" src={state_farm}/>;
        case 'USAA':
            return <img className={props.className} alt="carrier" src={usaa}/>;
        case 'CICA':
            return <img className={props.className} alt="carrier" src={cica}/>;
        default:
          return <img className={props.className} alt="carrier" src={moo}/>;
      }
}

export default CarrierLogo;