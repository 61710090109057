export const GET_STATES = 'GET_STATES';
export const STATES_ERROR = 'STATES_ERROR';
export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const AGENT_QUOTES = 'AGENT_QUOTES';
export const AGENT_QUOTES_ERROR = "AGENT_QUOTES_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_CONSUMER = "SET_LOADING_CONSUMER";
export const GET_QUOTES= "GET_QUOTES";
export const GET_QUOTES_ERROR= "GET_QUOTES_ERROR";
export const TAB_1_AGENT_QUOTES="TAB_1_AGENT_QUOTES";
export const TAB_2_AGENT_QUOTES="TAB_2_AGENT_QUOTES";
export const TAB_3_AGENT_QUOTES="TAB_3_AGENT_QUOTES";
export const UPDATE_PROGRESS_BAR= "UPDATE_PROGRESS_BAR";