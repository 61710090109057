import React, { Component } from 'react';
import { withRouter } from 'react-router';
import '../components/ProgressBar.css';
import Number_1_selected from '../../../assets/ProgressBar/Number_1_selected.svg';
import Number_1_selectedPng from '../../../assets/ProgressBar/Number_1_selected.png';
import Number_2 from '../../../assets/ProgressBar/Number_2.svg';
import Number_2Png from '../../../assets/ProgressBar/Number_2.png';
import Number_2_selected from '../../../assets/ProgressBar/Number_2_selected.svg';
import Number_2_selectedPng from '../../../assets/ProgressBar/Number_2_selected.png';
import Number_3 from '../../../assets/ProgressBar/Number_3.svg';
import Number_3Png from '../../../assets/ProgressBar/Number_3.png';
import Number_3_selected from '../../../assets/ProgressBar/Number_3_selected.svg';
import Number_3_selectedPng from '../../../assets/ProgressBar/Number_3_selected.png';
import Number_check from '../../../assets/ProgressBar/Number_check.svg';
import Number_checkPng from '../../../assets/ProgressBar/Number_check.png';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {updateProgressBar} from '../../../store/actions/consumerActions';
import { isMacOS } from '../../../utils/isMacOS';
import { isFirefox } from '../../../utils/isFirefox';
class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 1,
      completed: [],
    }
  }

  componentDidMount() {
    this.setProgress();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.shouldUpdateProgressBar !== this.props.shouldUpdateProgressBar && this.props.shouldUpdateProgressBar == true) {
      this.setProgress();
      this.props.updateProgressBar(false);
    }
  }

  setProgress() {
    switch (this.props.location.pathname) {
      case '/':
        this.setState({selected: 1, completed: []})
        window.scrollTo(0,0);
        break;
      case '/quotes-list':
        this.setState({selected: 2, completed: [1]})
        break;
      case '/application':
        this.setState({selected: 3, completed: [1,2]})
        break;
      case '/confirmation':
        this.setState({selected: null, completed: [1,2,3]})
        break;
      default:
        this.setState({selected: 1, completed: []})
        window.scrollTo(0,0);
        break;
    }
  }

  render() {
    const step1Completed = this.state.completed.includes(1);
    const step2Completed = this.state.completed.includes(2);
    const step3Completed = this.state.completed.includes(3);

    return (
      <nav className={`d-flex align-items-center justify-content-between progressBarContainer ${!isMacOS() && "not-mac-os"}`}>
        <div className={`number-container p-relative ${this.state.selected == 1 ? "selected": step1Completed ? "completedStep":""}`}>
          <img src={ !isMacOS() && isFirefox() ? (step1Completed ? Number_checkPng : Number_1_selectedPng) : (step1Completed ? Number_check : Number_1_selected)} />
          <p className="mb-0 label">Information</p>
        </div>
        <div style={{flex: 1}}></div>
        <div className={`number-container p-relative ${this.state.selected == 2 ? "selected": step2Completed ? "completedStep" : ""} ${step1Completed && !step2Completed ? "currentStep":""}`}>
          <img src={ !isMacOS() && isFirefox() ? (step2Completed ? Number_checkPng : this.state.selected == 2 ? Number_2_selectedPng : Number_2Png) : (step2Completed ? Number_check : this.state.selected == 2 ? Number_2_selected : Number_2)} />
          <p className="mb-0 label">Quotes</p>
        </div>
        <div style={{flex: 1}}></div>
        <div className={`number-container p-relative ${this.state.selected == 3 ? "selected": step3Completed ? "completedStep" : ""} ${step2Completed && !step3Completed ? "currentStep":""}`}>
          <img src={ !isMacOS() && isFirefox() ? (step3Completed ? Number_checkPng : this.state.selected == 3 ? Number_3_selectedPng : Number_3Png) : (step3Completed ? Number_check : this.state.selected == 3 ? Number_3_selected : Number_3)} />
          <p className="mb-0 label">Application</p>
        </div>
      </nav>
    );
  }
}

const mapStateToProps  = (state) => ({
  shouldUpdateProgressBar: state.consumer.shouldUpdateProgressBar,
})

export default compose(
  connect(mapStateToProps, {updateProgressBar}), 
  withRouter)(ProgressBar);
