import React from 'react';
import ionOpenOutline from '../../../../assets/Quotes/ionOpenOutline.svg'

const HowDoThesePlansWork = props => {

  return (
    <div className={`howDoThesePlansWork fs-18 d-flex align-items-center justify-content-between ${props.className} fw-600`} onClick={()=> props.togglePlanModal()}>        
      How do these plans work?
      <img src={ionOpenOutline} />
  </div>
  );
};

export default HowDoThesePlansWork;
