import {
    GET_QUOTES,
    GET_QUOTES_ERROR,
    UPDATE_PROGRESS_BAR,
    SET_LOADING_CONSUMER,
    GET_STATES,
    STATES_ERROR
} from '../types'
import axios from 'axios';

export const getQuotes = (generalInfo, application_id) => async dispatch => {
    
    try{
        const res = await axios.post(process.env.REACT_APP_API_URL + 'quotes', {
            general_info: generalInfo,
            application_id:  application_id
        })
        dispatch( {
            type: GET_QUOTES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: GET_QUOTES_ERROR,
            payload: e,
        })
    }

}

export const updateProgressBar = (value) => async dispatch => {
    dispatch({
        type: UPDATE_PROGRESS_BAR,
        payload: value
    })
}

export const setLoading = (value) => async dispatch => {
    dispatch({
        type: SET_LOADING_CONSUMER,
        payload: value
    })
}

export const updateApplicationState = (application_id, app_status, carrier_name, insurance_amount) => async dispatch => {
    
    try{
        axios.defaults.withCredentials = true;
        axios.patch(process.env.REACT_APP_API_URL + `applications/${application_id}`, {
            status: app_status,
            name: carrier_name,
            insurance_amount: insurance_amount
        });
    }
    catch(e){
        dispatch( {
            type: GET_QUOTES_ERROR,
            payload: e,
        })
    }

}

export const getStates = () => async dispatch => {
    
    try{
        const res = await axios.get(process.env.REACT_APP_API_URL + 'states')
        dispatch( {
            type: GET_STATES,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: STATES_ERROR,
            payload: console.log(e),
        })
    }

}