import React from 'react';
import download from '../../../../assets/Cards/download.svg';

const DownloadPolicy = props => {
  return (
       <div className={props.class} onClick={()=> {
          window.open(`${process.env.REACT_APP_PDF_URL + props.policy_pdf_path}`, '_blank').focus();
       }}>
            <img src={download} />
            <div className="infoText download">Download policy summary</div>
       </div>
  );
};

export default DownloadPolicy;
