import React, {useState} from 'react';
import editIcon from '../../../../assets/Quotes/editIcon.svg';
import editIconActive from '../../../../assets/Quotes/editIconActive.svg';

const EditCoverageLink = props => {
   const [isHovered, setIsHovered] = useState(false);   

   const handleMouseEnter = () => {
     setIsHovered(true);
   };
   
   const handleMouseLeave = () => {
     setIsHovered(false);
   };

   const imageSrc = isHovered ? editIconActive : editIcon;

  return (
    <div   
      onClick={props.openModal}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave} 
      className="d-flex mx-1 align-items-center fs-15 deepGray editCoverageLinkContainer pe-auto c-pointer">
       <img className="mr-1" src={imageSrc} />
       Edit Coverage
    </div>
  );
};

export default EditCoverageLink;
