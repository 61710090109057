import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import MobileDetect from 'mobile-detect';
import ApplicationLayout from '../components/ApplicationLayout';
import FixedButton from '../../../common/Buttons/components/FixedButton';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {updateProgressBar} from '../../../store/actions/consumerActions';
import { getStates } from '../../../store/actions/consumerActions';
class Application extends Component {
  constructor(props) {
    super(props);

    if (sessionStorage.getItem('generalInfo')) {
      const {
        insured_name,
        mainPhone,
        email,
        valid,
        source
      } = JSON.parse(sessionStorage.getItem('generalInfo'));

      this.state = {
        insurance_amount_st: null,
        name: null,
        gender: null,
        years: null,
        coverage: null,
        livingState: null,
        insured_name,
        mainPhone,
        alternatePhone: '',
        email,
        valid,
        applicantNameError: false,
        applicantLastNameError: false,
        error: '',
        insuringMsg: '',
        source,
        states: [],
      };
    } else {
      this.state = {
        insurance_amount_st: null,
        name: null,
        gender: null,
        years: null,
        coverage: null,
        livingState: null,
        insuring: null,
        insured_name: '',
        mainPhone: '',
        alternatePhone: '',
        email: '',
        valid: true,
        applicantNameError: false,
        applicantLastNameError: false,
        error: '',
        insuringMsg: '', 
        source: "",
        states: [],
      };
    }
  }

  componentDidMount() {
    this.props.getStates();
    this.props.updateProgressBar(true);
    document.querySelector('header').scrollIntoView();
    this.setLettersOnly();
    this.setNumberMask();


    if (
      sessionStorage.getItem('generalInfo') &&
      sessionStorage.getItem('quote')
    ) {
      const { gender, years, coverage, livingState, source, smoked } = JSON.parse(
        sessionStorage.getItem('generalInfo')
      );

      const { insurance_amount_st, name, plan_type, meta_info } = JSON.parse(
        sessionStorage.getItem('quote')
      );

      const mooQuoteGI =  sessionStorage.getItem('mooQuoteGI');

      const productType = sessionStorage.getItem('productType');

      this.setState({
        insurance_amount_st,
        name,
        plan_type,
        gender,
        years,
        coverage: this.setCoverageToShow(coverage),
        livingState,
        source,
        mooQuoteGI,
        productType,
        smoked,
        metaInfo: meta_info
      });

      axios.defaults.withCredentials = true
        
      axios.get(process.env.REACT_APP_API_URL + 'track/application' + '/' + sessionStorage.getItem('application_id'),{ params: {coverage: coverage, path: "/application" }});
    }
    if (this.isMobile()) {
      this.handleKeyboard();
      this.handleFocus();
    }

  }

  componentDidUpdate() {
    setTimeout(function(){
      if(document.querySelector('div.application'))
        document.querySelector('div.application').classList.remove('d-none');
    }, 50)
  }

  isMobile = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
  };

  handleKeyboard = () => {
    // const currentHeight = window.innerHeight;

    // window.addEventListener('resize', () => {
    //   if (window.innerHeight < currentHeight - 100) {
    //     document.querySelector('.fixed-btn-mobile').classList.add('d-none');
    //   } else {
    //     document.querySelector('.fixed-btn-mobile').classList.remove('d-none');
    //   }
    // });
  };

  handleFocus = () => {
    Array.prototype.forEach.call(document.forms[0], field => {
      if (field.type !== 'submit') {
        field.addEventListener('focusin', () => {
          document.querySelector('.fixed-btn-mobile').classList.add('d-none');
        });

        field.addEventListener('focusout', () => {
          document
            .querySelector('.fixed-btn-mobile')
            .classList.remove('d-none');
        });
      }
    });
  };

  handleBlur = e => {
    this.setState({ [e.target['name']]: e.target.value, valid: true });
  };

  setSelectedClasses() {
    if (this.state.insuring === 'myself') {
      document.getElementById('myself').classList.add('gender-selected');
    }
    if (this.state.insuring === 'someone else') {
      document.getElementById('someone').classList.add('gender-selected');
    }
  }

  setCoverageToShow(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  goBack = () => {
    sessionStorage.setItem('application-form', JSON.stringify(this.state));
    this.props.history.goBack();
  };

  toggleInsuringSelect = e => {
    if (document.querySelector('.gender-selected')) {
      document
        .querySelector('.gender-selected')
        .classList.remove('gender-selected');
      e.target.classList.add('gender-selected');
    } else {
      e.target.classList.add('gender-selected');
    }

    this.setState({ insuring: e.target.innerText.toLowerCase() });
    setTimeout(() => {
      if (this.isMobile()) {
        this.handleFocus();
      }
    }, 500);
  };


  validateMainPhone = () => {
    if (this.state.mainPhone && this.state.mainPhone.length === 14) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  setNumberMask = () => {
    document.getElementById('mainPhone').addEventListener('input', function(e) {
      const x = e.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });

    document
      .getElementById('alternatePhone')
      .addEventListener('input', function(e) {
        const x = e.target.value
          .replace(/\D/g, '')
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2]
          ? x[1]
          : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      });
  };

  setLettersOnly = () => {
    if (document.getElementById('insured_name'))
      document
      .getElementById('insured_name')
      .addEventListener('input', function(e) {
        const x = e.target.value.replace(/[^a-zA-Z ]/g, '');
        e.target.value = x;
      });
  };

  validateForm = form => {
    let errors = [];

    Array.prototype.filter.call(form, field => {
      if (
        field.value === '' &&
        field.type !== 'submit' &&
        field.id !== 'alternatePhone'
      ) {
        errors.push(field);
      }
    });

    form.classList.add('was-validated');

    if (errors.length) {
      errors[0].focus();
      this.setState({ valid: false });
      return false;
    }

    this.setState({ valid: true });
    return true;
  };

  validateNumber = e => {
    if (e.target.value.length === 14) {
      e.target.classList.add('valid');
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.classList.add('d-none');
      }
    } else {
      e.target.classList.remove('valid');
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.classList.remove('d-none');
      }
    }
  };

  saveApplication = e => {
    e.preventDefault();
    const form = e.target;

    setTimeout(() => {
      if (this.validateForm(form) && this.state.valid) {
        const {
          insured_name,
          mainPhone,
          alternatePhone,
          email, 
          source
        } = this.state;

        const model = {
          insured_name,
          phone_number: mainPhone.replace(/\D/g, ''),
          phone_number_alt: alternatePhone.replace(/\D/g, ''),
          email,
          source
        };

        const newApp = {
          user_info: JSON.parse(sessionStorage.getItem('userInfo')),
          general_info: JSON.parse(sessionStorage.getItem('generalInfo')),
          quote: JSON.parse(sessionStorage.getItem('quote')),
          application: model,
          application_id: sessionStorage.getItem('application_id'),
          path: '/confirmation'
        };

        axios
          .post(process.env.REACT_APP_API_URL + 'applications', newApp, {
            'Content-Type': 'application/json'
          })
          .then(res => {
            if (res.status === 201) {
              sessionStorage.setItem('reference_number', res.data.reference_number);
              sessionStorage.setItem('agent_phone_number', res.data.agent_phone_number);
              this.props.history.push('/confirmation');
            }
          })
          .catch(error => {
            this.setState({ error: 'Something went wrong, please try again.' });

            if (error.response.status === 422) {
              const { errors } = error.response.data;

              if (errors.phone_number) {
                document.getElementById('mainPhone').focus();
                this.setState({
                  error: errors.phone_number[0]
                });
              }
              if (errors.phone_number_alt) {
                document.getElementById('alternatePhone').focus();
                this.setState({
                  error: errors.phone_number_alt[0]
                });
              }
              if (errors.email) {
                document.getElementById('email').focus();
                this.setState({
                  error: errors.email[0]
                });
              }
            }
            if (error.response.status === 404) {
              this.setState({
                error: 'Something went wrong, please try again.'
              });
            }

            setTimeout(() => {
              this.setState({ error: '' });
            }, 3000);
          });
      }
    }, 500);
  };

  render() {
    let button = <FixedButton text="Back to Quotes" handleClick={this.goBack} class={"backToQuotesContainer"} />;
    return sessionStorage.getItem('generalInfo') &&
      sessionStorage.getItem('quote') ? (
      <section className="col-12 px-0 position-static">
        {button}

        <ApplicationLayout
          toggleInsuringSelect={this.toggleInsuringSelect}
          handleBlur={this.handleBlur}
          validateNumber={this.validateNumber}
          saveApplication={this.saveApplication}
          {...this.state}
          {...this.props}
        />
      </section>
    ) : (
      <Redirect to="/" />
    );
  }
}


const mapStateToProps  = (state) => ({
  states: state.agents.states
})

export default compose(
  connect(mapStateToProps, {updateProgressBar, getStates}))(Application);