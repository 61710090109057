import React from 'react';
import QuizBar from './../../Details/components/QuizBar';
import QuizQuestion from './../../Details/components/Quiz/QuizQuestion';
import './ChildrenApplication.css';
import './../../Details/components/DetailsLayout.css';
import QuizNav from '../../../common/QuizNav/QuizNav';

const ChildrenApplicationLayout = props => {
  const isFirstQuestion =  props.currentQuestionI == 0;
  const showPrevious = !isFirstQuestion;
  const isContactInfo = props.currentQuestion.type === "QuizContactInformation";
  const goingBackToOptions = props.currentQuestion.type === "QuizTwoOptionsSelect" && props.enableContinueForTwoOptions;
  const showContinue = (!isContactInfo && props.currentQuestion.type !== "QuizStateSelect") ||
                         goingBackToOptions || 
                         (props.currentQuestion.type == "QuizTwoOptionsSelect" && !isFirstQuestion) || 
                         (props.currentQuestion.type == "QuizStateSelect" && props.currentValueFullfilled());

  return (
    <section className="childrenApplication">
      <div className='blobWrapper'>
        <div className="mx-0 c-quoting-blob py-6 content">
          <h3 className='title text-center'>Children’s Life Insurance Quotes</h3>
          <QuizBar childrenApplication {...props} />
          {
            props.questions.map((question, i)=> 
              <QuizQuestion 
                key={`quizQuestionKey${i}`}
                {...props}
                question={question}
                questionI={i}
                childrenApplication
              />
           )
          }
          <QuizNav 
            showPrevious={showPrevious} 
            showContinue={showContinue}
            previousQuestion={props.previousQuestion}
            currentValueFullfilled={props.currentValueFullfilled}
            currentQuestionI={props.currentQuestionI}
            nextQuestion={props.nextQuestion}
          />
        </div>
      </div>


      <p className="fields-required text-danger">{props.errorMsg}</p>
    </section>
  );
};

export default ChildrenApplicationLayout;
