import React from 'react';
import PropTypes from 'prop-types';
import './Buttons.css';

const BackButton = props => {
  const handleSubmit = event => {
    if(props.route != "//choicemutual.com" && props.route != "http://localhost:3001") {
      event.preventDefault();

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = props.route;

      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = 'previous_url';
      hiddenInput.value = JSON.parse(sessionStorage.getItem('generalInfo'))['previous_url'];

      form.appendChild(hiddenInput);
      document.body.appendChild(form);
      form.submit();
    }
  };

  return (
    <a
      href={props.route}
      target="_parent"
      className="btn btn-block back-btn"
      onClick={handleSubmit}
      onMouseEnter={props.onMouseEnter}
    >
      {props.text}
    </a>
  );
};

BackButton.defaultProps = {
  text: 'back'
};

BackButton.propTypes = {
  route: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func
};

export default BackButton;
