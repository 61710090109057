import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import DetailsLayout from '../components/DetailsLayout';
import Loader from '../../../common/Loader/components/loader';
import queryString from 'query-string';
import { emailValidator } from '../../../utils/EmailValidator';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {updateProgressBar, setLoading} from '../../../store/actions/consumerActions';
import { getStates } from '../../../store/actions/consumerActions';
import { animateScroll as scroll, scroller } from 'react-scroll';
import $ from 'jquery';

class Details extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    let params = queryString.parse(this.props.location.search);
    // Questions displayed on the quiz
    const questions = [{
      text: "What is your gender?",
      type: "QuizTwoOptionsSelect",
      options: ["Male", "Female"],
      btnClass: "genderSize",
      handler: this.toggleGenderSelect,
      value: "gender"
    },
    {
      text: "What is your date of birth?",
      type: "QuizDateSelect",
      validation: this.validateDateOfBirth,
      value: "dob"
    },
    {
      text: "What is your state of residence?",
      type: "QuizStateSelect",
      validation: this.validateState,
      value: "livingState"
    },
    {
      text: "Have you smoked or used tobacco in the last 12 months?",
      type: "QuizTwoOptionsSelect",
      options: ["Yes", "No"],
      btnClass: "tobaccoSize",
      textClass: "tobaccoLabel",
      handler: this.toggleSmokeSelect,
      value: "smoked"
    },
    {
      text: "How much coverage are you interested in?",
      type: "QuizCoverageSelect",
      validation: this.validateCoverage,
      value: "coverage"
    },
    {
      text: "Please enter your contact information",
      type: "QuizContactInformation",
    }]

    if(sessionStorage.getItem('goback') === 'true' && sessionStorage.getItem('generalInfo')){
      const {
        gender,
        month,
        day,
        year,
        years,
        livingState,
        insured_name,
        mainPhone,
        email,
        smoked,
        coverage,
        valid,
        previous_url,
        source,
        ip_address,
        scroll_percentage
      } = JSON.parse(sessionStorage.getItem('generalInfo'));
      this.state = {
        gender,
        month,
        day,
        year,
        years,
        livingState,
        smoked,
        coverage,
        insured_name,
        mainPhone,
        email,
        states: [],
        valid,
        errorMsg: '',
        genderMsg: '',
        previous_url: previous_url,
        smokedMsg: '',
        questions,
        currentQuestion: questions[0],
        currentQuestionI: 0,
        enableContinueForTwoOptions: true,
        isNavigationDisabled: false,
        highQuotesEnabled: sessionStorage.getItem('highQuotesEnabled'),
        source,
        ip_address,
        scroll_percentage
      };

    }else if(this.paramsExist(params)){
      const {
        gender,
        dob_month,
        dob_day,
        dob_year,
        livingState,
        smoked,
        coverage,
        insured_name,
        mainPhone,
        email,
        previous_url,
        high_quotes_enabled,
        source,
        ip_address,
        scroll_percentage
      } = params;

      this.state = {
        gender: gender,
        month: dob_month,
        day: dob_day,
        year: dob_year,
        years: null,
        livingState: livingState,
        smoked: smoked,
        coverage: coverage,
        insured_name: insured_name,
        mainPhone: mainPhone,
        email: email,
        previous_url: previous_url,
        states: [],
        hasParams: true,
        valid: false,
        errorMsg: '',
        genderMsg: '',
        smokedMsg: '',
        questions,
        currentQuestion: questions[0],
        currentQuestionI: 0,
        enableContinueForTwoOptions: false,
        isNavigationDisabled: false,
        highQuotesEnabled: high_quotes_enabled,
        source,
        ip_address,
        scroll_percentage
      };

    }else {
      this.state = {
        gender: null,
        month: '',
        day: '',
        year: '',
        years: null,
        livingState: '',
        smoked: null,
        coverage: '',
        states: [],
        valid: false,
        errorMsg: '',
        genderMsg: '',
        previous_url: process.env.REACT_APP_HOME_URL,
        smokedMsg: '',
        questions,
        currentQuestion: questions[0],
        currentQuestionI: 0,
        enableContinueForTwoOptions: false,
        isNavigationDisabled: false,
        highQuotesEnabled: false,
        source: null,
        ip_address: null,
        scroll_percentage: ''
      };
      sessionStorage.setItem('application_id', '')
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.updateProgressBar(true);
    this.props.getStates();
    let event_type = 'quote_details';
    if(sessionStorage.getItem('goback') === 'true'){
      event_type = 'edit_information';
      sessionStorage.setItem('goback', 'false');
    } else if(this.state.hasParams)
      this.handleDetails();

    this.handleFocusEdge();
    if (sessionStorage.getItem('application_id'))
      axios.get(process.env.REACT_APP_API_URL + 'track/'+ event_type + '/' + sessionStorage.getItem('application_id'), { withCredentials: true, params: {coverage: this.state.coverage, path: "/"} });
    document.querySelector('header').scrollIntoView();
  }

  componentWillUnmount() {
    this._isMounted = false;
 }

  isMobile = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
  };

  paramsExist = params => {
    for(var prop in params) {
      if(prop === 'previous_url')
        continue;
      if (Object.prototype.hasOwnProperty.call(params, prop)) {
        return true;
      }
    }
    return false;
  }

  handleKeyboard = e => {
    const currentHeight = window.innerHeight;

    window.addEventListener('resize', () => {
      if (window.innerHeight < currentHeight - 100) {
        document.querySelector('.close-btn-mobile').classList.add('d-none');
      } else {
        document.querySelector('.close-btn-mobile').classList.remove('d-none');
      }
    });
  };

  handleFocusEdge() {
    const mdEdge = new MobileDetect(window.navigator.userAgent);

    if (mdEdge.ua.indexOf('Edge') !== -1) {
      const month = document.getElementById('month');
      const day = document.getElementById('day');
      const year = document.getElementById('year');

      month.addEventListener('focusin', () => {
        month.setAttribute('placeholder', '');
      });

      month.addEventListener('focusout', () => {
        month.setAttribute('placeholder', 'MM');
      });

      day.addEventListener('focusin', () => {
        day.setAttribute('placeholder', '');
      });

      day.addEventListener('focusout', () => {
        day.setAttribute('placeholder', 'DD');
      });

      year.addEventListener('focusin', () => {
        year.setAttribute('placeholder', '');
      });

      year.addEventListener('focusout', () => {
        year.setAttribute('placeholder', 'YYYY');
      });
    }
  }

  handleBlur = e => {
    let validator;
    if(e.target['name'] == "mainPhone")
      validator = this.validatePhone;
    else if(e.target['name'] == "insured_name")
      validator = this.validateName;
    else
      validator = this.validateEmail;
  
    this.setState({ [e.target['name']]: e.target.value, valid: true }, validator);
  };

  handleOption = (e,name) => {
    this.setState({ [name]: e.value });
    setTimeout((name) => {
      if (this._isMounted){
        if(name == 'livingState')
          this.validateState()
        else
          this.validateCoverage()
      }
    }, 800, name);
  };

  handleMonth = e => {
    let val = e.value

    if(val.length > 2){
      val = val.substring(0,2);
    }else if(val.length === 2 && (val < 1 || val > 12)){
      val = val.substring(0,1)
    }
    if (val === 0) {
      val = '';
    }
    this.setState({ month: val });
    setTimeout(() => {
      if (this._isMounted) 
        this.setYears();
    }, 1000);
    if (this.state.error_date_of_birth && this.state.error_date_of_birth != ''){
      setTimeout(() => {
        if (this._isMounted)
          this.validateDateOfBirth();
      }, 800);
    }
  };

  handleDay = e => {
    let val = e.value;

    if(val.length > 2){
      val = val.substring(0,2)
    }else if(val.length === 2 && (val < 1 || val > 31)){
      val = val.substring(0,1)
    }

    if (val === 0) {
      val = '';
    }
    this.setState({ day: val });
    setTimeout(() => {
      if (this._isMounted)
        this.setYears();
    }, 1000);

    if (this.state.error_date_of_birth && this.state.error_date_of_birth != ''){
      setTimeout(() => {
        if (this._isMounted)
          this.validateDateOfBirth();
      }, 800);
    }
  };

  handleYear = e => {
    let val = e.value;

    if(val.length > 4){
      val = val.substring(0,4)
    }else if(val.length === 1 && val > 2){
      val = ''
    }else if(val.length === 2 && (val < 19 || val > 20)){
      val = val.substring(0,1)
    }else if(val.length === 3 && (val < 190 || val > 201)){
      val = val.substring(0,2)
    }else if(val.length === 4 && val > 2018){
      val = val.substring(0,3)
    }

    if (val === 0) {
      val = '';
    }
    this.setState({ year: val });
    setTimeout(() => {
      if (this._isMounted)
        this.setYears();
    }, 1000);
    
    if (this.state.error_date_of_birth && this.state.error_date_of_birth != ''){
      setTimeout(() => {
        if (this._isMounted)
          this.validateDateOfBirth();
      }, 800);
    }
  };

  setNumberMask = () => {
    document.getElementById('mainPhone').addEventListener('input', function(e) {
      const x = e.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });
  };

  setLettersOnly = () => {
    document
      .getElementById('insured_name')
      .addEventListener('input', function(e) {
        const x = e.target.value.replace(/[^a-zA-Z ]/g, '');
        e.target.value = x;
      });
  };


  toggleGenderSelect = gender => {
    // Mark the option before the animation happens
    const genderToUnmark = gender == "Male" ? "Female" : "Male";
    const genderToMark = gender == "Male" ? "Male" : "Female";

    document.getElementById(`quizButton_genderSize_${genderToUnmark}`).classList.remove("selected");
    document.getElementById(`selectedImg_genderSize_${genderToUnmark}`).classList.remove("d-block");
    document.getElementById(`quizButton_genderSize_${genderToMark}`).classList.add("selected");
    document.getElementById(`selectedImg_genderSize_${genderToMark}`).classList.add("d-block");

    this.animateTransition(this.state.currentQuestionI + 1, this.state.currentQuestionI);
    setTimeout(() => {
      if (this._isMounted)
        this.setState(
        { 
          gender: gender.toLowerCase(), 
          currentQuestion: this.state.questions[this.state.currentQuestionI + 1],
          currentQuestionI: this.state.currentQuestionI + 1,
          prevQuestionI: this.state.currentQuestionI
        });
    }, 500);

  };

  toggleSmokeSelect = smoked => {
    // Mark the option before the animation happens
    const smokeToUnmark = smoked == "Yes" ? "No" : "Yes";
    const smokeToMark = smoked == "Yes" ? "Yes" : "No";

    document.getElementById(`quizButton_tobaccoSize_${smokeToUnmark}`).classList.remove("selected");
    document.getElementById(`selectedImg_tobaccoSize_${smokeToUnmark}`).classList.remove("d-block");
    document.getElementById(`quizButton_tobaccoSize_${smokeToMark}`).classList.add("selected");
    document.getElementById(`selectedImg_tobaccoSize_${smokeToMark}`).classList.add("d-block");

    this.animateTransition(this.state.currentQuestionI + 1, this.state.currentQuestionI);
    $(`.continueQ.questionI${this.state.currentQuestionI}`).removeClass("disabled");
    $(`.continueQ.questionI${this.state.currentQuestionI}`).addClass("enabled");

    setTimeout(() => {
      if (this._isMounted)
        this.setState(
        { 
          smoked: smoked.toLowerCase(),
          currentQuestion: this.state.questions[this.state.currentQuestionI + 1],
          currentQuestionI: this.state.currentQuestionI + 1,
          prevQuestionI: this.state.currentQuestionI
        });
    }, 500);
  };

  getYears = () => {
    const { year, month, day } = this.state;
    if (year && month && day) {
      const birthDate = moment();
      birthDate.set('year', parseInt(year, 10));
      birthDate.set('month', parseInt(month - 1, 10));
      birthDate.set('date', parseInt(day, 10));

      const currentDate = moment();

      return currentDate.diff(birthDate, 'years');
    }

  }

  setYears = () => {
    const years = this.getYears();
    if (years < 100) {
      this.setState({ years, errorMsg: '' });
    } else {
      this.setState({
        errorMsg: '',
        valid: false
      });
    }
  };

  handleDetailsSubmit = e => {
    e.preventDefault();
    this.handleDetails();
  };

  handleDetails = () => {

    if (this.validate()) {
      this.props.setLoading(true);
      const years = this.getYears();

      const {
        gender,
        month,
        day,
        year,
        livingState,
        smoked,
        coverage,
        insured_name,
        mainPhone,
        email,
        valid,
        previous_url,
        source,
        ip_address,
        scroll_percentage
      } = this.state;

      // Potencially fix strange situation of https://quotes.choicemutual.com/ as previous_url when it shouldn't be possible
      // Not sure if this is a cache/browser-os versions
      const previous_url_val = previous_url == "https://quotes.choicemutual.com/" && (source === "DGQ" || source == "MGQ") ? process.env.REACT_APP_HOME_URL : previous_url;

      const generalInfo = {
        gender,
        month: month.toString(),
        day: day.toString(),
        year: year.toString(),
        years,
        livingState,
        smoked,
        coverage,
        insured_name,
        mainPhone,
        email,
        valid,
        previous_url: previous_url_val,
        device_and_browser: window.navigator.userAgent,
        screen_resolution: `${screen.width} x ${screen.height}`,
        path: '/',
        source,
        ip_address,
        scroll_percentage
      };
      const userInfo = {
        previousUrl: previous_url_val,
        deviceAndBrowser: window.navigator.userAgent
      };

      sessionStorage.setItem('generalInfo', JSON.stringify(generalInfo));
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      sessionStorage.setItem('highQuotesEnabled', this.state.highQuotesEnabled);
      axios.defaults.withCredentials = true
      axios
        .post(process.env.REACT_APP_API_URL + 'quotes', {
          general_info: generalInfo,
          application_id: sessionStorage.getItem('application_id'),
          available_coverages: sessionStorage.getItem('availableCoverages')
        })
        .then(
          res => {
            const featured_quotes = [
              ...res.data.featured_immediate_coverage_plan,
              ...res.data.featured_guaranteed_issue_plan
            ];

            sessionStorage.setItem('license', res.data.license);

            sessionStorage.setItem(
              'featured_quotes',
              JSON.stringify(featured_quotes)
            );

            sessionStorage.setItem('quotes', JSON.stringify(res.data.quotes));
            sessionStorage.setItem('application_id', res.data.application_id)
            if (this._isMounted)
              this.props.setLoading(false);
            
            // Info page for /quote leads
            if ( generalInfo.previous_url && generalInfo.previous_url.includes("/quote/") ) { 
              this.props.history.push('/quote-only');
            } else {
              this.props.history.push('/quotes-list');
            }
          },
          error => {
            this.props.setLoading(false);
            this.setSelectedClasses();
          }
        );
    } else {
      this.setState({ errorMsg: '* All fields are required.' });
      this.setYears();
    }
  };

  validateGender = () => {
    if (this.state.gender) {
      this.setState({ error_gender: '', valid: true });
      return true;
    } else {
      this.setState({ error_gender: '* Please choose one.', valid: false });
      this.scrollIfOutOfView('gender')
    }
  };

  validateSmoked = () => {
    if (this.state.smoked) {
      this.setState({ error_smoker: ''});
      return true;
    } else {
      this.setState({ error_smoker: '* You must choose one.'});
      this.scrollIfOutOfView('tobacco');
    }
  };

  validateDateOfBirth = () => {
    this.setState({error_date_of_birth: ''})
    this.setState({error_month: ''})
    this.setState({error_day: ''})
    this.setState({error_year: ''})
    let {month, day, year} = this.state;
    month = parseInt(month, 10);
    day = parseInt(day, 10);
    year = parseInt(year, 10);
    if(month && day && year){
      var date = new Date(month+'/'+day+'/'+year);
      if(date.getMonth()+1 === month && date.getDate() === day && date.getFullYear() === year && year > 1910)
        return true;
      else {
        this.setState({error_date_of_birth: '* Please enter a valid birthdate.'})
        this.setState({error_month: 'invalid month.'})
        this.setState({error_day: 'invalid day.'})
        this.setState({error_year: 'invalid year.'})
        this.scrollIfOutOfView('dob');
      }
    } else {
      if (!month)
        this.setState({error_month: 'invalid month.'})
      if (!day)
        this.setState({error_day: 'invalid day.'})
      if (!year)
        this.setState({error_year: 'invalid year.'})
      this.setState({error_date_of_birth: '* Please enter a valid birthdate.'})
      this.scrollIfOutOfView('dob');

      return false;
    }
  };

  validateEmail = () => {
    this.setState({error_email: ''})
    const email = this.state.email;
    if(typeof emailValidator(email) === 'string') {
      this.setState({error_email: emailValidator(email)});
      this.scrollIfOutOfView('email-wrapper');
      return false;
    } else
      return true
  };

  validatePhone = () => {
    this.setState({error_phone: ''})
    const phone = this.state.mainPhone;
    if(phone){
      var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
      var digits = phone.replace(/\D/g, "");
      if(phoneRe.test(digits))
        return true;
      else {
        this.setState({error_phone: 'Phone is invalid.'})
        this.scrollIfOutOfView('phone-wrapper');
      }
    } else {
        this.setState({error_phone: 'Phone is required.'})
        this.scrollIfOutOfView('phone-wrapper');
      }
    return false;
  };

  validateName = () => {
    this.setState({error_name: ''})
    if(this.state.insured_name){
      return true;
    }
    this.setState({error_name: 'Please enter your full name.'})
    this.scrollIfOutOfView('name-wrapper');
    return false;
  };

  validateState = () => {
    this.setState({error_state: ''})
    if(this.state.livingState)
      return true;

    this.setState({error_state: 'Please select the state you live in.'})
    this.scrollIfOutOfView('state');
    return false;
  };

  validateCoverage = () => {
    this.setState({error_coverage: ''})
    if(this.state.coverage)
      return true;

    this.setState({error_coverage: 'Please select your coverage.'})
    this.scrollIfOutOfView('coverageWrapper');
    return false;
  };

  validate = () => {
    if (
      this.validateGender() &&
      this.validateDateOfBirth() &&
      this.validateState() &&
      this.validateSmoked() &&
      this.validateCoverage() &&
      this.validateName() &&
      this.validatePhone() &&
      this.validateEmail()
    ) {
      return true;
    } else {
      return false;
    }
  };

  scrollIfOutOfView = (id) => {
    if (!this.isElementInViewport(document.getElementById(id))){
      scroller.scrollTo(id, { duration: 1500, delay: 0, smooth: true, offset: -24 })
    }
  }

  isElementInViewport = (el) => {
    if (el){
      var rect = el.getBoundingClientRect();

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      );
    }
    return true;
  }

  previousQuestion = () => {
    const newCurrentQuestion = this.state.questions[this.state.currentQuestionI - 1];
    this.animateTransition(this.state.currentQuestionI-1, this.state.currentQuestionI)
    this.setState(
      { 
        currentQuestion: newCurrentQuestion,
        currentQuestionI: this.state.currentQuestionI - 1,
        // When going back to Gender and Tobacco questions we show continue button
        enableContinueForTwoOptions: newCurrentQuestion.value == "gender" || newCurrentQuestion.value == "smoked",
        prevQuestionI: this.state.currentQuestionI
      });
  };


  nextQuestion = () => {
    if(this.state.isNavigationDisabled || !this.currentValueFullfilled() || (this.state.currentQuestion['validation'] && !this.state.currentQuestion["validation"]))
      return;
    this.setState({isNavigationDisabled: true})
    this.animateTransition(this.state.currentQuestionI+1, this.state.currentQuestionI)
    
    const newCurrentQuestion = this.state.questions[this.state.currentQuestionI + 1];
    setTimeout(()=> {
      if (this._isMounted) 
        this.setState(
          { 
            currentQuestion: newCurrentQuestion,
            currentQuestionI: this.state.currentQuestionI + 1,
            enableContinueForTwoOptions: this.showContinueHandler(newCurrentQuestion),
            prevQuestionI: this.state.currentQuestionI
          }, ()=> {
            if(this.state.currentQuestion.type == "QuizContactInformation") {
              this.setLettersOnly();
              this.setNumberMask();
            }
            this.setState({isNavigationDisabled: false})
          });
      }, 500)

  };

  moveQuestion = (i) => {
    const newCurrentQuestion = this.state.questions[i];

    this.animateTransition(i, this.state.currentQuestionI)
    setTimeout(()=> {
      if (this._isMounted)
        this.setState({
          currentQuestion: newCurrentQuestion,
          currentQuestionI: i,
          enableContinueForTwoOptions: this.showContinueHandler(newCurrentQuestion),
          prevQuestionI: this.state.currentQuestionI
        })
    }, 500)
  }

  showContinueHandler = (newCurrentQuestion) => {
    let enableContinueForTwoOptions = false;
    // For tobacco and gender we show continue if the value is populated when showing them
    if((newCurrentQuestion.value == "smoked" && this.state.smoked) || (newCurrentQuestion.value == "gender" && this.state.gender))
      enableContinueForTwoOptions = true;

    return enableContinueForTwoOptions;
  }

  animateTransition = (nextQuestionI, currentQuestionI) => {
    if(nextQuestionI > currentQuestionI) {
      document.getElementsByClassName(`question${currentQuestionI}`)[0].classList.add("slideLeft");
      document.getElementsByClassName(`question${nextQuestionI}`)[0].classList.remove("slideLeft");
    }
    else {
      document.getElementsByClassName(`question${currentQuestionI}`)[0].classList.add("slideRight");
      document.getElementsByClassName(`question${nextQuestionI}`)[0].classList.remove("slideRight");
    }

  } 

  currentValueFullfilled = () => {
    if(this.state[this.state.currentQuestion.value])
      return true;
    else if(this.state.currentQuestion.value == "dob")
      return this.state.day && this.state.month && this.state.year;

    false
  } 

  render() {
    return (
      <div className="col-12 px-0">
        {this.props.loading ? (
          <Loader />
        ) : (
          <DetailsLayout
            {...this.state}
            {...this.props}
            handleOption={this.handleOption}
            handleMonth={this.handleMonth}
            handleYear={this.handleYear}
            handleBlur={this.handleBlur}
            handleDay={this.handleDay}
            toggleGenderSelect={this.toggleGenderSelect}
            toggleSmokeSelect={this.toggleSmokeSelect}
            handleDetailsSubmit={this.handleDetailsSubmit}
            setYears={this.setYears}
            previousQuestion={this.previousQuestion}
            nextQuestion={this.nextQuestion}
            moveQuestion={this.moveQuestion}
            currentValueFullfilled={this.currentValueFullfilled}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps  = (state) => ({
  loading: state.consumer.loading,
  states: state.agents.states,
})

export default compose(
  connect(mapStateToProps, {updateProgressBar, setLoading, getStates}))(Details);
