import React from 'react';
import { Dropdown } from '../Dropdown/Dropdown';
import './QuoterDropdown.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const QuoterDropdown = props => {
  return (
    <Dropdown
        name={props.name}
        title={props.title}
        list={props.list}
        onChange={props.onChange}
        arrowUpIcon={ <FontAwesomeIcon 
          icon={faChevronUp}
          style={{color: "#006D6F", fontSize: 13}}
        />}
        arrowDownIcon={   <FontAwesomeIcon 
          icon={faChevronDown}
          style={{color: "#006D6F", fontSize: 13}}
        />} 
        select={props.selected}
        titleBold={props.titleBold}
        scrollTo={props.scrollTo}
    />
  );
};

QuoterDropdown.propTypes = {

};

export default QuoterDropdown;
