import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CoverageRange from '../../components/CoverageRange/CoverageRange';
import Cross from '../../../../assets/Modals/roundedCross.svg'
import { numberAsCurrency } from '../../../../utils/CurrencyHelper';
import "./CoverageModal.css"

const CoverageModal = props => {
   const [coverage, setCoverage] = useState(props.coverage);

   const updateCoverage = (newCoverage) => {
     setCoverage(newCoverage.value);
   };

   useEffect(() => {
    setCoverage(props.coverage);
   }, [props.coverage]);


  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      className={props.className}
    >
      <div className="px-5 modal-header d-flex align-items-center text-center justify-content-center">
        <div className="d-flex flex-column">
          <span className="fs-18">Coverage Amount:</span>
          <h3 className="mt-1 mb-0">{numberAsCurrency(coverage)}</h3>
        </div>
        <img className="modalCross cPointer" onClick={props.toggle} src={Cross} />
      </div>
      <ModalBody className="px-5 d-flex">
          <CoverageRange 
            livingState={props.livingState}
            years={props.years}
            onChange={updateCoverage}
            coverage={coverage}
            highQuotesEnabled={props.highQuotesEnabled}
            className="modalCoverageRange"
           />
      </ModalBody>
      <ModalFooter className="px-5 d-flex align-items-center">
          <p 
            className="quoteBtn updateCovBtn navMainItems mt-4" 
            onClick={()=> { props.updateCoverage({value: coverage}, true); props.toggle(); }}>
            Update Coverage
          </p>
      </ModalFooter>
    </Modal>
  );
};

export default CoverageModal;
