import React from 'react';
import { createRoot } from 'react-dom/client';

import './scss/custom.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import store from './store/store'
import {Provider} from 'react-redux';
import { appsignal } from "./appsignal";
import { ErrorBoundary } from "@appsignal/react";
 

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const root = document.getElementById('root');
const rootInstance = createRoot(root);

rootInstance.render(
  <ErrorBoundary instance={appsignal}>
    <Provider store={store}>
       <App />
    </Provider>
  </ErrorBoundary>
);
registerServiceWorker();
