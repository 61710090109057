import React from 'react';
import MobileDetect from 'mobile-detect';

export function withMobileDetect(WrappedComponent, selectData) {
    return class extends React.Component {
  
    isMobile = () => {
      const md = new MobileDetect(window.navigator.userAgent);
      return md.mobile();
    };
  
      render() {
        return <WrappedComponent {...this.props} isMobile={this.isMobile} />;
      }
    };
}