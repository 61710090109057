import React, { useState, useEffect } from 'react';
import BackButton from '../../../common/Buttons/components/BackButton';
import confirmationIc from '../../../assets/icConfirmation.svg';
import ProgressBar from '../../../common/ProgressBar/containers/ProgressBar';
import './ConfirmationLayout.css';
import arrowBack from '../../../assets/Quotes/arrowBack.svg';
import circle1 from '../../../assets/confirmationCircle1.svg';
import circle2 from '../../../assets/confirmationCircle2.svg';
import ExitIntentPopup from './ExitIntentPopup';

const ConfirmationLayout = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showPopup] = useState(false);

  const reference_number = props.reference_number;
  const agent_phone_number = props.agent_phone_number;
  let editInfoBtnText = windowWidth >= 540 && windowWidth < 768 ? "Back to Site" : "Back to Choice Mutual Site";
  const exitPopupBreak = 1024;

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const mobileTriggerExitPopup = () => {
    return window.innerWidth <= exitPopupBreak;
  };

  return (
    <section className="confirmation">
      <ExitIntentPopup showPopup={showPopup} mobileTriggers={mobileTriggerExitPopup} agent_phone_number={agent_phone_number}  />
      <div className="progressBarWrapper d-md-none d-flex align-items-center content">
        <ProgressBar />
      </div>
      <div className="topTitleWrapper">
        <div className="d-flex flex-wrap mx-auto topTitle">
          <div className="d-flex flex-column justify-content-between col-12 col-mdxl-6 px-0 titleBody mb-1 mb-mdxl-0">
            <h2>Congratulations!</h2>
            <h5>Your application has been successfully sent.</h5>
          </div>
          <div className="d-flex col-12 col-mdxl-6 px-0 mt-2 mt-mdxl-0">
            <img className="confirmation-ic mx-auto" src={confirmationIc} alt="confirmation" />
          </div>
        </div>

      </div>
      <div className="content wrapper">
        <div className="text-wrapper">
          <div className="d-flex text-wrapper">
            <img className="circle" src={circle1}></img>
            <div>
              <span className="main-text">Write down your application reference number: </span><b className='underline-text'>{reference_number}</b>
              <br/><br/>
              When one of our agents gives you a call, he or she will provide your with this reference number so you can trust that you’re speaking with a Choice Mutual agent.
            </div>
          </div>
          <div className="d-flex">
          <img className="circle" src={circle2}></img>
            <div><span className="main-text">Add <b className='underline-text'>{agent_phone_number ? agent_phone_number : "(775) 571-1290"}</b> as a contact in your phone.</span>
              <br/><br/>
              This is the number we will call you from. Many phones are set up to automatically block calls from unrecognized numbers, so adding our number to your contacts will ensure you receive our call. We won’t need much of your time — usually just about 10 minutes.
              <br/><br/>
              <span className="italic-17-600">If we are unable to reach you by phone in the coming days, your application will be canceled.</span>
            </div>
          </div>
        </div>
        <div className="buttons-wrapper mx-auto">
          <BackButton 
            route={`${process.env.REACT_APP_HOME_URL}`} 
            text="Start New Application" 
          />
          <div
            className="editInfoBtn pointer d-flex align-items-center justify-content-end"
            onClick={props.handleBackChoiceMutual}
          > 
            <img src={arrowBack}></img>
            <span>{editInfoBtnText}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationLayout;