import React, { Component } from 'react';
import QuoteOnlyLayout from '../components/QuoteOnlyLayout';

class QuoteOnly extends Component {
  state = {
    featured_quotes: [],
    quotes: [],
    modal: false,
    coverage: '10000',
    years: null,
    livingState: null
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  componentDidMount() {
    document.querySelector('header').querySelector('.col-md-6').style.visibility = "hidden";
    document.querySelector('section').querySelector('.mt-3').style.fontSize = "31.5px";
    document.querySelector('section').querySelector('.content').style.fontSize = "19.5px";
  }

  toggleBolder = target => {
    document.querySelector('.sort-bolder').classList.remove('sort-bolder');
    target.classList.add('sort-bolder');
  };

  render() {
    return (

      <section className="col-12 col-md-10 offset-md-1 col-xl-12 offset-xl-0 px-0 position-static sector mt-md-0">
        <QuoteOnlyLayout/>
      </section>
    );
  }
}

export default QuoteOnly;
