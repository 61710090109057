export const getAvailableCoverages = (livingState, years, highQuotesEnabled, childrenApplication = false) => {
    if(childrenApplication)
      return sortCoverages(range(5000, 50000, 5000));

    var coverages;
    if(!livingState && !years)
      coverages = [].concat(range(2000, 20000), range(20000, 40000, 5000));
    else {
      if(livingState === "Washington")
        coverages = range(5000, 10000);
      else
        coverages = range(3000, 10000);
    }
  
    if (aetnaPlanStates.includes(livingState) || !livingState) { // Aetna Rates
      if (yearsBetween(years, 18, 85)) {
        coverages = [].concat(coverages, range(11000, 19000), range(20000, 50000, 5000));
      } else if (yearsBetween(years, 86, 89)) {
        coverages = [].concat(coverages, range(11000, 20000), range(21000, 25000));
      }
      if(!livingState || livingState !== "Washington")
        coverages = [].concat([2000], coverages);
    } else if (livingState === "New York") {
      if (years <= 44) {
        coverages = [].concat(range(5000, 35000), [25000]);
      } else if (years < 86) {
        coverages = [].concat(range(2000, 35000), [25000]);
      } if (years >= 81 && years <= 85) {
        coverages = [].concat(range(3000, 25000), []);
      }
    } else if (years !== null && years <= 44) {
      coverages = [].concat(range(5000, 19000), range(20000, 40000, 5000));
    } else if (years !== null && years < 86) {
      coverages = [].concat(range(2000, 19000), range(20000, 40000, 5000));
    }
  
    if (highQuotesEnabled == 'true'){
      coverages = [].concat(coverages, [50000, 75000, 100000, 150000, 200000, 250000])
    }
  
    return sortCoverages(removeDuplicates(coverages));
};

const sortCoverages = coverages => {
    return coverages.sort((a, b) => {
      return a - b;
    });
};
  
const removeDuplicates = coverages => {
  return coverages.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
}

const yearsBetween = (years, min, max) => {
    return years >= min && years <= max;
};

const range = (start, end, step = 1000) => {
  const len = Math.floor((end - start) / step) + 1
  return Array(len).fill().map((_, idx) => start + (idx * step))
};


// aetna_accendo
const aetnaPlanStates = [
    "Alabama",
    "Alaska", 
    "Arizona",
    "Arkansas",
    "District of Columbia",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii", 
    "Idaho", 
    "Illinois",
    "Indiana",
    "Iowa",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Michigan" ,
    "Missouri",
    "Mississippi",
    "North Carolina",
    "Nebraska",
    "New Jersey",
    "Ohio",
    "Rhode Island",
    "South Carolina",
    "Tennessee",
    "Texas",
    "Virginia",
    "Wisconsin",
    "West Virginia",
    "Maine",
    "Minnesota",
    "Montana",
    "Nevada",
    "New Hampshire",
    "New Mexico",
    "North Dakota",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "South Dakota",
    "Utah",
    "Vermont",
    "Washington",
    "Wyoming",
];
  