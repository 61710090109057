import React from 'react';
import PropTypes from 'prop-types';
import Quote from './Quote';

const QuotesList = props => {
  const { productType } = props
  const term_life = productType === "term_life"

  return (
    <div className={`quotes-list px-0 content ${term_life && props.stickyBanner ? "sticky-margin" : term_life ? "non-sticky-margin" : ""} ${(term_life && props.bannerWasDisplayed) && "banner-was-displayed"}`}>
      {productType === "final_expense" && <div className="quotes-list-header d-flex justify-content-between">
        <h3 className="mb-0 fs-18">
           Here are all Whole Life plans for you to compare:
        </h3>
      </div>
      }
      <div className="d-flex flex-column quotesWrapper">
        {props.quotes.map((quote,i) => (
          <Quote
            key={`${quote.id}_normal_quotes_${i}`}
            quote={quote}
            saveQuote={props.saveQuote}
            isMobile={props.isMobile}
            notAvailable={quote.hasOwnProperty('reason')}
            livingState={props.livingState}
            age={props.age}
            openCoverageInfoModal={props.openCoverageInfoModal}
            closeCoverageInfoModal={props.closeCoverageInfoModal}
            term_life={term_life}
            index={i}
            setCoverageTooltip={props.setCoverageTooltip}
            coverageTooltipKey={props.coverageTooltipKey}
            coverageTooltipContent={props.coverageTooltipContent}
          />
        ))}
      </div>
    </div>
  );
};

QuotesList.propTypes = {
  quotes: PropTypes.array.isRequired
};

export default QuotesList;
