import React from 'react';
import ProgressBar from '../../ProgressBar/containers/ProgressBar';
import logo from '../../../assets/Header/Logo Group.svg';
import logoPng from '../../../assets/Header/Logo Group.png';
import phoneCall from '../../../assets/Header/phone.svg';
import phoneCallPng from '../../../assets/Header/phone.png';
import hoverPhoneCall from '../../../assets/Header/hoverPhone.svg';
import hoverPhoneCallPng from '../../../assets/Header/hoverPhone.png';
import PropTypes from 'prop-types';
import './Header.css';
import queryString from 'query-string';
import { isMacOS } from './../../../utils/isMacOS'
import { isFirefox } from './../../../utils/isFirefox'
class Header extends React.Component {
  state = {
    phone: "800-644-2926",
    mobileViewPhone: "800 644 2926",
    phoneImg: !isMacOS() && isFirefox() ? phoneCallPng : phoneCall
  };

  componentDidUpdate() {
    this.refs.top.scrollIntoView(true);
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if(params["phone"])
      sessionStorage.setItem('phone', params["phone"]);
    
    if(sessionStorage.getItem("phone"))
      this.setState({phone: sessionStorage.getItem("phone"), mobileViewPhone: sessionStorage.getItem("phone")})
  }

  render() {
    return (
      <header ref="top" className={"d-flex align-items-center content"}>
        <div className={`col-md-3 d-md-flex h-auto px-0`}>
          <figure className="logo mb-0 py-2">
            {this.props.logoLink ?
            <a href="https://choicemutual.com" target="_parent" rel="noopener noreferrer">
              <img src={!isMacOS() && isFirefox() ? logoPng : logo} alt="Company Logo" />
            </a> 
            : 
            <img src={!isMacOS() && isFirefox() ? logoPng : logo} alt="Company Logo" />}
          </figure>
        </div>
        {<div className="col-md-6 d-none d-md-flex mdProggressBarContainer">
          {this.props.progressBar && <ProgressBar />}
        </div>}

        {<div
          className="col-6 col-md-3 px-0 d-flex justify-content-end"
        >
          <div className="row contact text-center mx-0 text-sm-right">
            <div className="col-12 px-0">
              <span className='d-block'>Speak to a salary-based agent</span>
            </div>
            <div className="col-12 d-flex text-center phoneWrapper px-0 justify-content-center align-items-center">
              <a className="d-flex" onMouseEnter={()=> this.setState({phoneImg: !isMacOS() && isFirefox() ? hoverPhoneCallPng : hoverPhoneCall})} onMouseLeave={()=> this.setState({phoneImg: !isMacOS() && isFirefox() ? phoneCallPng : phoneCall})} href={`tel:${this.state.phone}`}>
               <img src={this.state.phoneImg}></img>
              {this.state.phone}
              </a>
            </div>
            <div className="col-12 schedule px-0 d-sm-flex justify-content-sm-center">
              <span className='d-block'>Weekdays 8AM - 5PM PST</span>
            </div>
          </div>
        </div>}
      </header>
    );
  }
}

Header.propTypes = {
  mode: PropTypes.string
};

export default Header;
