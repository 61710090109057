import React from 'react';
import { getAvailableCoverages } from '../../../../utils/CoverageHelper';
import { numberAsCurrency } from '../../../../utils/CurrencyHelper';
import Slider from '@mui/material/Slider';
import {isIOS} from '../../../../utils/DeviceDetector';
import './CoverageRange.css'

const CoverageRange = props => {
  const { livingState, years, onChange, coverage, highQuotesEnabled } = props;

  const coverages = getAvailableCoverages(livingState, years, highQuotesEnabled);
  const max_coverage = coverages[coverages.length - 1];
  const min_coverage = coverages[0];

  const handleChange = (event, newValue) => {
    if (isIOS && window.innerWidth < 810 && event.type === 'mousedown') {
      return;
    }

    onChange({ value: newValue });
  };

  return (
    <div className={`coverageRange w-100 ${props.className}`}>
        <Slider
          value={parseInt(coverage,10)}
          marks={coverages.map((c)=> ({value: c}))}
          min={min_coverage}
          max={max_coverage}
          onChange={handleChange}
          valueLabelDisplay="off"
          step={null}
          sx={{
            '& .MuiSlider-mark': {
              width: 0,
            },
            '& .MuiSlider-track': {
              height: '8px',
              borderRadius: '100px',
              background: '#006D6F',
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#0000001A',
              height: '8px',
              borderRadius: '100px',
            },
            '& .MuiSlider-thumb': {
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              backgroundColor: '#FFFFFF',
              transition: 'box-shadow 0.2s', // Add a transition for a smooth effect
              boxShadow: '0px 0px 10px rgba(0, 30.80, 31.69, 0.40) !important',
              '&:hover, &.Mui-active': {
                boxShadow: '0px 0px 15px rgba(0, 30.80, 31.69, 0.40) !important',
              },
            },
          }}
        />
        <div className="d-flex align-items-center justify-content-between fs-12">
            <div>{numberAsCurrency(min_coverage)}</div>
            <div>{numberAsCurrency(max_coverage)}</div>
        </div>
    </div>
  );
};

export default CoverageRange;
