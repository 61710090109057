import React, { Component } from 'react';
import QuizBarLayout from './QuizBarLayout'

class QuizBar extends Component {
  constructor(props) {
    super(props)
  }

  isCheckededQuestion = (current, question) => {
    if (question <= current){
      return true
    }
    return false
  }

  isCurrentQuestion = (current, question) => {
    if (question == current){
      return true
    }
    return false
  }

  getQuestionNumberById = (id) => {
    if(this.props.childrenApplication) {
      switch (id){
        case 'state-question-indicator':
          return 0
        case 'coverage-question-indicator':
          return 1
        case 'age-question-indicator':
          return 2
        }
    }

    switch (id){
      case 'gender-question-indicator':
        return 0
      case 'age-question-indicator':
        return 1
      case 'state-question-indicator':
        return 2
      case 'tobacco-question-indicator':
        return 3
      case 'coverage-question-indicator':
        return 4
      }
  }

  handleClick = (e) => {
    this.props.moveQuestion(this.getQuestionNumberById(e.target.id))
  }

  render() {
    return(
      <QuizBarLayout
        {...this.props}
        isCheckededQuestion={this.isCheckededQuestion}
        isCurrentQuestion={this.isCurrentQuestion}
        handleClick={this.handleClick}
        />
    )
  }
}

export default QuizBar;
