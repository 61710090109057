import React from 'react';
import Titles from '../../../common/Titles/components/Titles';

const QuoteOnlyLayout = () => {
  return (
    <div className="no-quotes col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 px-0 mt-lg-3">
      <br></br>
      <br></br>
      <br></br>
      <Titles
        title="Thank you!"
        subtitle=""
      />
      <br></br>
      <p className="content">
        We successfully recieved your information.
        A licensed agent will be in touch very soon to ask you a few questions to find you the lowest rate possible.
      </p>
    </div>
  );
};

export default QuoteOnlyLayout;
