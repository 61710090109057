import React, {useState} from 'react';
import Switch from "react-switch";

const PlanTypeSwitcher = props => {
  // switch on -> term_life
  // switch off -> final_expense
  const handleChange = nextChecked => {
    if(nextChecked)
      props.toggleProductType("term_life");
    else 
      props.toggleProductType("final_expense");
  };

  return (
    <label className={`d-flex align-items-center planTypeSwitcherWrapper ${props.containerClass}`}>
      <span className={props.productType === "term_life" ? "deepGray":"active"}>{props.label1}</span>
      <Switch 
        onChange={handleChange}
        checked={props.productType === "term_life"} 
        onColor="#FFFFFF"
        offColor="#FFFFFF"
        onHandleColor="#006D6F"
        offHandleColor="#006D6F"
        uncheckedIcon={false} 
        checkedIcon={false} 
        className="planTypeSwitcher"
        boxShadow="none"
        activeBoxShadow="none"
        width={66}
        height={28}
      />
      <span className={props.productType === "term_life" ? "active":"deepGray"}>{props.label2}</span>
    </label>
  );
};


PlanTypeSwitcher.defaultProps = {
  label1: 'Whole',
  label2: "Term"
};

export default PlanTypeSwitcher;
