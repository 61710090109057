import React from 'react';

const CPUnitsEquation = props => {
  const { metaInfo } = props

  return (
    <div className={`cpEquation mx-auto ml-sm-auto mt-1 deepGray ${props.className}`}>
          <div className="d-flex justify-content-around fs-15">
            <div className="d-flex flex-column align-items-center">
              <div className="h-15">{metaInfo.units_count}</div>
              <div className="fs-10">Units</div>
            </div>
            x
            <div className="d-flex flex-column align-items-center">
              <div className="h-15">{metaInfo.coverage_per_unit}</div>
              <div className="fs-10">Coverage/Unit</div>
            </div>
            =
            <div className="d-flex flex-column align-items-center">
              <div className="h-15">{metaInfo.total_coverage}</div>
              <div className="fs-10">Actual Coverage</div>
            </div>
          </div>
    </div>
  );
};

export default CPUnitsEquation;
