import React, { useState, useEffect } from 'react';
import ApplicationInfo from './ApplicationInfo';
import backgroundLG from '../../../assets/submitBackgroundLG.svg'
import backgroundXL from '../../../assets/submitBackgroundXL.svg'
import backgroundXXL from '../../../assets/submitBackgroundXXL.svg'
import backgroundXXXL from '../../../assets/submitBackgroundXXXL.svg'
import ProgressBar from '../../../common/ProgressBar/containers/ProgressBar';

const Application = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = windowWidth >= 1440 ? backgroundXXXL :
                   windowWidth >= 1280 ? backgroundXXL :
                   windowWidth >= 1120 ? backgroundXL :
                   windowWidth >= 960 ? backgroundLG : null
  const policyTypeText = props.productType === "final_expense" ? (windowWidth > 513 && windowWidth < 960) || windowWidth > 1079 ? "Whole Life Insurance" : "Whole Life" : "Term Life"

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
}, []);

  return (
    <div className="application d-none" style={{backgroundImage: `url(${imageUrl})`}}>
       <div className="progressBarWrapper d-md-none d-flex align-items-center content">
        <ProgressBar />
      </div>
      <div className="topTitle text-sm-center">
        <div className="titleBody container-cxs mt-64 container-cmd content">
          <h2>Submit Your Application</h2>
          <h5><span className='md-lb-after'>Now let's verify your info and submit your application. </span>This part is no obligation — we won’t move forward until we speak with you.</h5>
        </div>
      </div>
      <ApplicationInfo 
          policyTypeText={policyTypeText}
          {...props} 
        />
    </div>
  );
};

export default Application;
