import React, { Component } from 'react';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import ChildrenApplicationLayout from '../components/ChildrenApplicationLayout';
import queryString from 'query-string';
import { getStates } from '../../../store/actions/consumerActions';
import { emailValidator } from '../../../utils/EmailValidator';

class ChildrenApplication extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    // Questions displayed on the quiz
    const questions = [{
      text: "What is your state of residence?",
      type: "QuizStateSelect",
      validation: this.validateState,
      value: "livingState"
    },
    {
      text: "How much coverage are you interested in?",
      type: "QuizCoverageSelect",
      validation: this.validateCoverage,
      value: "coverage"
    },
    {
      text: "How old is the child you wish <span class='sm-break-together'>to insure?</span>",
      type: "QuizAgeSelect",
      value: "childAge",
      description: "You will be able to add additional children in the next step. Enter the age for any one child here."
    },
    {
      text: "Please enter your contact information",
      type: "QuizContactInformation",
    }]

    this.state = {
      questions,
      currentQuestion: questions[0],
      currentQuestionI: 0,
      livingState: '',
    };
  
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.toggleProgressBar(false);
    this.props.getStates();
  }

  currentValueFullfilled = () => {
    if(this.state[this.state.currentQuestion.value])
      return true;
    else if(this.state.currentQuestion.value == "dob")
      return this.state.day && this.state.month && this.state.year;

    false
  } 

  handleOption = (e,name) => {
    this.setState({ [name]: e.value });
    setTimeout((name) => {
      if (this._isMounted){
        if(name == 'livingState')
          this.validateState()
        else
          this.validateCoverage()
      }
    }, 800, name);
  };

  validateState = () => {
    this.setState({error_state: ''})
    if(this.state.livingState) {
      return true;
    }

    this.setState({error_state: 'Please select the state you live in.'})
    this.scrollIfOutOfView('state');
    return false;
  };

  validateCoverage = () => {
    this.setState({error_coverage: ''})
    if(this.state.coverage)
      return true;

    this.setState({error_coverage: 'Please select your coverage.'})
    this.scrollIfOutOfView('coverageWrapper');
    return false;
  };

  animateTransition = (nextQuestionI, currentQuestionI) => {
    if(nextQuestionI > currentQuestionI) {
      document.getElementsByClassName(`question${currentQuestionI}`)[0].classList.add("slideLeft");
      document.getElementsByClassName(`question${nextQuestionI}`)[0].classList.remove("slideLeft");
    }
    else {
      document.getElementsByClassName(`question${currentQuestionI}`)[0].classList.add("slideRight");
      document.getElementsByClassName(`question${nextQuestionI}`)[0].classList.remove("slideRight");
    }

  } 

  previousQuestion = () => {
    const newCurrentQuestion = this.state.questions[this.state.currentQuestionI - 1];
    this.animateTransition(this.state.currentQuestionI-1, this.state.currentQuestionI)
    this.setState(
      { 
        currentQuestion: newCurrentQuestion,
        currentQuestionI: this.state.currentQuestionI - 1,
        // When going back to Gender and Tobacco questions we show continue button
        enableContinueForTwoOptions: newCurrentQuestion.value == "gender",
        prevQuestionI: this.state.currentQuestionI
      });
  };


  nextQuestion = () => {
    if(this.state.isNavigationDisabled || !this.currentValueFullfilled() || (this.state.currentQuestion['validation'] && !this.state.currentQuestion["validation"]))
      return;
    this.setState({isNavigationDisabled: true})
    this.animateTransition(this.state.currentQuestionI+1, this.state.currentQuestionI)
    
    const newCurrentQuestion = this.state.questions[this.state.currentQuestionI + 1];
    setTimeout(()=> {
      if (this._isMounted) 
        this.setState(
          { 
            currentQuestion: newCurrentQuestion,
            currentQuestionI: this.state.currentQuestionI + 1,
            enableContinueForTwoOptions: this.showContinueHandler(newCurrentQuestion),
            prevQuestionI: this.state.currentQuestionI
          }, ()=> {
            if(this.state.currentQuestion.type == "QuizContactInformation") {
              this.setLettersOnly();
              this.setNumberMask();
            }
            this.setState({isNavigationDisabled: false})
          });
      }, 500)

  };

  moveQuestion = (i) => {
    const newCurrentQuestion = this.state.questions[i];

    this.animateTransition(i, this.state.currentQuestionI)
    setTimeout(()=> {
      if (this._isMounted)
        this.setState({
          currentQuestion: newCurrentQuestion,
          currentQuestionI: i,
          enableContinueForTwoOptions: this.showContinueHandler(newCurrentQuestion),
          prevQuestionI: this.state.currentQuestionI
        })
    }, 500)
  }

  showContinueHandler = (newCurrentQuestion) => {
    let enableContinueForTwoOptions = false;
    // For tobacco and gender we show continue if the value is populated when showing them
    if((newCurrentQuestion.value == "gender" && this.state.gender))
      enableContinueForTwoOptions = true;

    return enableContinueForTwoOptions;
  }

  setLettersOnly = () => {
    document
      .getElementById('owner_name')
      .addEventListener('input', function(e) {
        const x = e.target.value.replace(/[^a-zA-Z ]/g, '');
        e.target.value = x;
      });
  };

  setNumberMask = () => {
    document.getElementById('mainPhone').addEventListener('input', function(e) {
      const x = e.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      e.target.value = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    });
  };

  handleDetailsSubmit = e => {
    e.preventDefault();
    this.handleDetails();
  };

  handleDetails = () => {

    if (this.validate()) {
      const {
        livingState,
        coverage,
        owner_name,
        mainPhone,
        email,
        childAge
      } = this.state;
      
      window.parent.location.href = `${process.env.REACT_APP_CHILD_PAGE_URL}?state=${livingState}&cov=${coverage}&ownerName=${owner_name.split(' ')[0]}&mainPhone=${mainPhone}&email=${email}&age=${childAge}`;
    } else {
      this.setState({ errorMsg: '* All fields are required.' });
    }
  };

  validateName = () => {
    this.setState({error_name: ''})
    if(this.state.owner_name){
      return true;
    }
    this.setState({error_name: 'Please enter your full name.'})
    this.scrollIfOutOfView('name-wrapper');
    return false;
  };

  validatePhone = () => {
    this.setState({error_phone: ''})
    const phone = this.state.mainPhone;
    if(phone){
      var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
      var digits = phone.replace(/\D/g, "");
      if(phoneRe.test(digits))
        return true;
      else {
        this.setState({error_phone: 'Phone is invalid.'})
        this.scrollIfOutOfView('phone-wrapper');
      }
    } else {
        this.setState({error_phone: 'Phone is required.'})
        this.scrollIfOutOfView('phone-wrapper');
      }
    return false;
  };

  validateEmail = () => {
    this.setState({error_email: ''})
    const email = this.state.email;
    if(typeof emailValidator(email) === 'string') {
      this.setState({error_email: emailValidator(email)});
      this.scrollIfOutOfView('email-wrapper');
      return false;
    } else
      return true
  };

  handleBlur = e => {
    let validator;
    if(e.target['name'] == "mainPhone")
      validator = this.validatePhone;
    else if(e.target['name'] == "owner_name")
      validator = this.validateName;
    else
      validator = this.validateEmail;
  
    this.setState({ [e.target['name']]: e.target.value, valid: true }, validator);
  };

  validateChildAge = () => {
    if(this.state.childAge)
      return true;

    return false;
  };


  validate = () => {
    if (
      this.validateChildAge() &&
      this.validateState() &&
      this.validateCoverage() &&
      this.validateName() &&
      this.validatePhone() &&
      this.validateEmail()
    ) {
      return true;
    } else {
      return false;
    }
  };

  scrollIfOutOfView = (id) => {
    if (!this.isElementInViewport(document.getElementById(id))){
      scroller.scrollTo(id, { duration: 1500, delay: 0, smooth: true, offset: -24 })
    }
  }

  isElementInViewport = (el) => {
    if (el){
      var rect = el.getBoundingClientRect();

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      );
    }
    return true;
  }

  render() {
    return (
      <div className="col-12 px-0">
        <ChildrenApplicationLayout 
          {...this.state}
          {...this.props}
          currentValueFullfilled={this.currentValueFullfilled}
          handleOption={this.handleOption}
          previousQuestion={this.previousQuestion}
          nextQuestion={this.nextQuestion}
          moveQuestion={this.moveQuestion}
          handleDetailsSubmit={this.handleDetailsSubmit}
          handleBlur={this.handleBlur}
        />
      </div>
    );
  }
}

const mapStateToProps  = (state) => ({
  states: state.agents.states
})

export default compose(
  connect(mapStateToProps, {getStates}))(ChildrenApplication);