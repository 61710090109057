import React from 'react';
import './QuizQuestion.css';
import QuoterDropdown from '../../../../common/QuoterDropdown/QuoterDropdown';

const months = [
  {
    label: '01 JAN',
    value: "1",
  },
  {
    label: '02 FEB',
    value: "2",
  },
  {
    label: '03 MAR',
    value: "3",
  },
  {
    label: '04 APR',
    value: "4",
  },
  {
    label: '05 MAY',
    value: "5",
  },
  {
    label: '06 JUN',
    value: "6",
  },
  {
    label: '07 JUL',
    value: "7",
  },
  {
    label: '08 AUG',
    value: "8",
  },
  {
    label: '09 SEP',
    value: "9",
  },
  {
    label: '10 OCT',
    value: "10",
  },
  {
    label: '11 NOV',
    value: "11",
  },
  {
    label: '12 DEC',
    value: "12",
  },
];

const days = Array.from({length: 31}, (_, i) => i + 1).map(i=> { return {label: i.toString(), value: i.toString()}});

// Calc the available years to select
var currentYear = new Date().getFullYear();
var minYear = currentYear - 90;
var maxYear = currentYear - 18;
var selectableYears = [];
for (var i = minYear; i <= maxYear; i++) {
  selectableYears.push(i);
}

const years = selectableYears.map(i=> { return {label: i.toString(), value: i.toString()}});
const defaultBornYear = currentYear - 65;

const QuizDateSelect = props => {

  return (
    <div>
      <div className='quizDateSelect d-flex'>
          <div className="col-4 mt-gutter-y px-date pl-0">
             <QuoterDropdown list={months} title="Month" onChange={props.handleMonth} name="month" selected={props.month ? {value: props.month.toString()} : null} titleBold />
           </div>

           <div className="col-4 mt-gutter-y px-date">
             <QuoterDropdown list={days} title="Day" onChange={props.handleDay} name="day" selected={props.day ? {value: props.day.toString()} : null} titleBold />
           </div>

           <div className="col-4 mt-gutter-y px-date pr-0">
              <QuoterDropdown 
                list={years}
                title="Year" 
                onChange={props.handleYear} 
                name="year" 
                selected={props.year ? {value: props.year.toString()} : null} 
                titleBold 
                scrollTo={defaultBornYear}
              />
           </div>
      </div>
        <div className="errWrapper">
          <small className="error">{props.error_date_of_birth}</small>
        </div>
     </div>
  );
};

export default QuizDateSelect;
