import React from 'react';
import './loader.css';
import spinner from '../../../assets/Loader/CM_loader.gif';
import spinnerBlack from '../../../assets/Gear-blk.gif';

const Loader = (props) => {
  return (
    <div className="loaderWrapper">
      <div className="loader">
        <img src={props.color === "black" ? spinnerBlack : spinner} alt="loading" />
        <p className="mt-4">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
