import React from 'react';
import Moo from '../../../assets/TrustedInsurers/MutualofOmaha.svg';
import MooPng from '../../../assets/TrustedInsurers/MutualofOmaha.png';
import Aetna from '../../../assets/TrustedInsurers/Aetna.svg';
import AetnaPng from '../../../assets/TrustedInsurers/Aetna.png';
import Aig from '../../../assets/TrustedInsurers/AIG.svg';
import AigPng from '../../../assets/TrustedInsurers/AIG.png';
import './TrustedInsurers.css';
import { isMacOS } from '../../../utils/isMacOS';
import { isFirefox } from '../../../utils/isFirefox';
import Globe from '../../../assets/TrustedInsurers/Globe Life.svg';
import GlobePng from '../../../assets/TrustedInsurers/Globe Life.png';
import AAA from '../../../assets/TrustedInsurers/AAA.svg';
import AAApng from '../../../assets/TrustedInsurers/AAA.png';
import ColonialPenn from '../../../assets/TrustedInsurers/ColonialPenn.svg';
import ColonialPennPng from '../../../assets/TrustedInsurers/ColonialPenn.png';
import StateFarm from '../../../assets/TrustedInsurers/State Farm.svg';
import StateFarmPng from '../../../assets/TrustedInsurers/State Farm.png';
import USAA from '../../../assets/TrustedInsurers/USAA.svg';
import USAAPng from '../../../assets/TrustedInsurers/USAA.png';
import AARP from '../../../assets/TrustedInsurers/AARP.svg';
import AARPPng from '../../../assets/TrustedInsurers/AARP.png';
import Trustage from '../../../assets/TrustedInsurers/Trustage.svg';
import TrustagePng from '../../../assets/TrustedInsurers/Trustage.png';
import Aflac from '../../../assets/TrustedInsurers/Aflac.svg';
import AflacPng from '../../../assets/TrustedInsurers/Aflac.png';
import LincolnHeritage from '../../../assets/TrustedInsurers/LincolnHeritage.svg';
import LincolnHeritagePng from '../../../assets/TrustedInsurers/LincolnHeritage.png';

const TrustedInsurers = props => {
  return ( 
    <div className="d-block quoting-logos-mobile">
        <div className="text-center max-400 mx-auto">
          You’re about to compare final expense insurance prices from over 25 insurers:
        </div>
        <div className="row align-items-center mt-3 mt-md-3 px-sm-3 first-row max-400">
            <div className="mb-4 mb-md-3 col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? GlobePng : Globe} />
            </div>
            <div className="mb-4 d-flex justify-content-center mb-2 mb-md-3 col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? AAApng : AAA} className="AAA" />
            </div>
             <div className="mb-4 mb-md-3 col-md-3 col-4 text-center">
               <img src={!isMacOS() && isFirefox() ? MooPng : Moo} />
            </div>
            <div className="mb-4 col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? ColonialPennPng : ColonialPenn} className="CP" />
            </div>
            <div className="mb-4  mb-md-4 col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? StateFarmPng : StateFarm} />
            </div>
            <div className="mb-4  mb-md-4 col-md-3 col-4 d-flex justify-content-center">
              <img src={!isMacOS() && isFirefox() ? USAAPng : USAA} className="USAA" />
            </div>
            <div className="mb-4  mb-md-4 col-md-3 col-4 text-center">
              <img src={!isMacOS() && isFirefox() ? AetnaPng : Aetna} className="AetnaTI" />
            </div>
            <div className="mb-4 mb-md-4 d-sm-block col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? AARPPng : AARP} className="AARP" />
            </div>
            <div className="mb-4  mb-md-3 col-md-3 col-4">
              <img src={!isMacOS() && isFirefox() ? TrustagePng : Trustage} />
            </div>
            <div className="mb-4  mb-md-3 col-md-3 col-4 d-flex justify-content-center">
              <img src={!isMacOS() && isFirefox() ? AflacPng : Aflac} className="Aflac" />
            </div>
            <div className="mb-4  mb-md-3 col-md-3 col-4 text-center">
              <img src={!isMacOS() && isFirefox() ? LincolnHeritagePng : LincolnHeritage} />
            </div>
            <div className="mb-4 mb-md-3 col-md-3 col-4 d-flex justify-content-center">
              <img src={!isMacOS() && isFirefox() ? AigPng : Aig} className="AIGTrustedInsurers" />
            </div>
        </div>
    </div>
  );
};

export default TrustedInsurers;