import React from 'react';
import PropTypes from 'prop-types';
import backImg from '../../../assets/navBack.svg';

const FixedButton = props => {
  return (
    <p
      className={`fixed-btn mb-0 d-flex align-items-center content mt-3 cPointer ${props.sticky ? "stickyBack" : ""} ${props.class}`}
      onClick={props.handleClick}
    >
      <img src={backImg} alt="back" />
      <span className="infoText editInformation">{props.text}</span>
    </p>
  );
};

FixedButton.defaultProps = {
  text: 'last page'
};

FixedButton.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  sticky: PropTypes.bool,
  class: PropTypes.string
};

export default FixedButton;
