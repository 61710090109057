import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Benefits from '../../../common/Benefits/components/Benefits';
import ProgressBar from '../../../common/ProgressBar/containers/ProgressBar';
import QuotesList from './QuotesList';
import BestQuote from './BestQuote';
import CoverageModal from './CoverageModal/CoverageModal';
import PlanTypeSwitcher from './PlanTypeSwitcher/PlanTypeSwitcher';
import EditCoverageLink from './EditCoverageLink/EditCoverageLink';
import PlanDescriptionModal from './PlanDescriptionModal/PlanDescriptionModal';
import HowDoThesePlansWork from './HowDoThesePlansWork/HowDoThesePlansWork';
import CoverageRange from '../components/CoverageRange/CoverageRange';
import './Quotes.css';

const QuotesLayout = props => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const quotes_length = props.quotes.length;

  useEffect(() => {
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleWindowResize);

    return () => {
        window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="p-relative d-flex flex-column">
      <div className="progressBarWrapper d-md-none d-flex align-items-center content">
        <ProgressBar />
      </div>
      <div className={`topTitle text-sm-center h-auto`}>
        <div className="d-flex flex-column titleBody container-cxs mt-64 container-cmd content text-center">
          <h2 className="yourQuotes mb-2">Your Quotes</h2>
          <div className="amountRow mx-auto">
              <h5 className="fs-18 lh-26">
                {quotes_length == 1 ? "There is one plan" : quotes_length >= 2 ? `There are ${quotes_length} plans` : ""} for a <div className="detailsHighlight d-inline-flex my-0">${props.setCoverageToShow(props.coverage)}</div> policy to cover a <div className="detailsHighlight d-inline-flex my-0">{`${props.years}-year-old ${props.gender}`}</div> living in <div className="detailsHighlight d-inline-flex my-0">{`${props.livingState}`}</div> who <div className="detailsHighlight d-inline-flex my-0">does {`${props.smoked === "no" ? "not" : ""}`}</div> use tobacco.
              </h5>
          </div>
        </div>
      </div>
      <div className={`d-mdxl-none text-sm-center stickyBanner ${props.bannerWasDisplayed && "banner-was-displayed"} ${props.stickyBanner ? "sticky-banner-show" : "sticky-banner-hide"}`}>
        <div className="container-cmd content d-flex align-items-center justify-content-between">
          <PlanTypeSwitcher toggleProductType={props.toggleProductType} productType={props.productType} />
          <EditCoverageLink openModal={props.toggle} />
        </div>
      </div>

      <div className="content edit-bar d-flex align-items-center justify-content-between d-mdxl-none">
        <PlanTypeSwitcher toggleProductType={props.toggleProductType} productType={props.productType} />
        <EditCoverageLink openModal={props.toggle} />
      </div>

      <div className="d-mdxl-flex justify-content-xl-center">
        <div className="col-12 col-mdxl-8 content expandLg quotesListParent">
          {props.productType !== "term_life" && <div className={`row featuredRow mx-0 justify-content-sm-center justify-content-lgxl-between justify-content-xl-between px-xl-0 justify-content-lg-start ${props.bannerWasDisplayed && "banner-was-displayed"} ${props.stickyBanner ? "sticky-margin" : "non-sticky-margin"}`}>
            {props.featured_quotes.length
              ? props.featured_quotes.map((quote,i)=> (
                  <BestQuote
                    index={i}
                    key={`${quote.id}_featured_${i}`}
                    quote={quote}
                    saveQuote={props.saveQuote}
                    unique = {props.featured_quotes.length === 1}
                  />
                ))
              : null}
          </div>}

 
          {props.productType === "final_expense" && <HowDoThesePlansWork className="d-mdxl-none" togglePlanModal={props.togglePlanModal} />}
  
          <QuotesList
            quotes={props.filteredQuotes}
            saveQuote={props.saveQuote}
            sortQuotesByRating={props.sortQuotesByRating}
            sortQuotesByPrice={props.sortQuotesByPrice}
            isMobile={props.isMobile}
            livingState={props.livingState}
            age={props.years}
            productType={props.productType}
            stickyBanner={props.stickyBanner}
            bannerWasDisplayed={props.bannerWasDisplayed}
            coverage={props.bannerWasDisplayed}
            setCoverageTooltip={props.setCoverageTooltip}
            coverageTooltipKey={props.coverageTooltipKey}
            coverageTooltipContent={props.coverageTooltipContent}
          />

          {props.productType === "term_life" && <HowDoThesePlansWork className="term-life d-mdxl-none" togglePlanModal={props.togglePlanModal} />}

        </div>  
        <div className="d-none d-mdxl-block col-12 col-mdxl-4 px-0 stickyParent">
          <div className="sticky-wrapper">
            <div className="sidebarContainer">
              <div className="d-flex flex-column policyTypeWrapper">
                <h3 className="fs-18">Policy Type</h3>
                <PlanTypeSwitcher 
                  toggleProductType={props.toggleProductType} 
                  productType={props.productType} 
                  containerClass=""
                  label1="Whole Life"
                  label2="Term Life"
                />
              </div>

              <Benefits productType={props.productType} togglePlanModal={props.togglePlanModal} />
            
              <div className="d-none d-mdxl-flex flex-column p-sticky stickyChangeAmount">
                <h3 className="fs-18 my-0">Coverage</h3>
                <div className='coverageLabel fs-15'>You're viewing quotes for a <span className="fw-500">${props.setCoverageToShow(props.coverage)}</span> {`${props.productType.replace(/_/g, ' ')}`} policy.</div>
                <CoverageRange 
                  livingState={props.livingState}
                  years={props.years}
                  onChange={props.updateCoverage}
                  coverage={props.coverage}
                  highQuotesEnabled={props.highQuotesEnabled}
                  className="mt-3 sidebarCoverageRange"
                />
              </div>
            </div>
          </div>
        </div>  
      </div>

        

       <CoverageModal {...props} className="coverageModal" updateCoverage={props.updateCoverage} />
       <PlanDescriptionModal {...props} className="planDescriptionModal" />
    </div>
  );
};

QuotesLayout.propTypes = {
  quotes: PropTypes.array.isRequired
};

export default QuotesLayout;
