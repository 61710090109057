import React from 'react';
import PropTypes from 'prop-types';
import arrowBack from '../../../assets/Quotes/arrowBack.svg'
import './Buttons.css';

const EditInfoLink = props => {
  return (
    <a
      href={props.route}
      target="_parent"
      className="editInfoBtn d-flex align-items-center justify-content-center"
      onClick={props.handleClick}
    > 
      <img src={arrowBack}></img>
      <span>{props.text}</span>
    </a>
  );
};

EditInfoLink.defaultProps = {
  text: 'back'
};

EditInfoLink.propTypes = {
  route: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func
};

export default EditInfoLink;
