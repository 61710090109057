import React, { Component } from 'react';
import MobileDetect from 'mobile-detect';
import NoQuotesLayout from '../components/NoQuotesLayout/NoQuotesLayout';
import DeepQuoteExploration from '../components/DeepQuoteExploration/DeepQuoteExploration';
import QuotesLayout from '../components/QuotesLayout';
import FixedButton from '../../../common/Buttons/components/FixedButton';
import axios from 'axios';
import { compose } from 'recompose';
import {withMobileDetect} from '../../../utils/hocs/mobileDetectHoc';
import { withRouter } from "react-router";
import {connect} from 'react-redux';
import {getQuotes, setLoading, updateProgressBar, updateApplicationState} from '../../../store/actions/consumerActions';
import Loader from '../../../common/Loader/components/loader';

class Quotes extends Component {
  cancelToken = null;
  state = {
    featured_quotes: [],
    quotes: [],
    mooQuoteGI: 0,
    modal: false,
    coverage: '10000',
    years: null,
    livingState: null,
    gender: null,
    smoked: null,
    stickyBanner: false,
    bannerWasDisplayed: false,
    windowWidth: window.innerWidth,
    productType: "final_expense",
    filteredQuotes: [],
    planModal: false,
    coverageTooltipKey: null,
    coverageTooltipContent: null
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  togglePlanModal = () => {
    this.setState({
      planModal: !this.state.planModal
    });
  };

  goBack = () => {
    sessionStorage.setItem('goback', true);
    this.props.history.goBack();
  };


  componentDidMount() {
    this.props.updateProgressBar(true);
    document.querySelector('header').scrollIntoView();
    if (sessionStorage.getItem('generalInfo')) {
      this.setState({
        coverage: JSON.parse(sessionStorage.getItem('generalInfo')).coverage,
        years: JSON.parse(sessionStorage.getItem('generalInfo')).years,
        livingState: JSON.parse(sessionStorage.getItem('generalInfo'))
          .livingState,
        gender:  JSON.parse(sessionStorage.getItem('generalInfo')).gender,
        smoked:  JSON.parse(sessionStorage.getItem('generalInfo')).smoked,
      });
    }
    if (sessionStorage.getItem('quotes')) {
      this.setState({ quotes: JSON.parse(sessionStorage.getItem('quotes')) }, ()=> {
        this.setState({filteredQuotes: this.state.quotes.filter((q) => q.product_type === this.state.productType)})
      });
    }
    if (sessionStorage.getItem('featured_quotes')) {
      this.setState({
        featured_quotes: JSON.parse(sessionStorage.getItem('featured_quotes'))
      });
    }
    if (sessionStorage.getItem('highQuotesEnabled')) {
      this.setState({
        highQuotesEnabled: sessionStorage.getItem('highQuotesEnabled')
      });
    }


    if(window.innerWidth < 810 ) {
      window.addEventListener('scroll', this.handleScroll);
    }

    window.addEventListener('resize', this.bindStickyBanner, false);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.bindStickyBanner, false);
    if (this.cancelToken) {
      this.cancelToken.cancel("Operation canceled as component unmounted.");
    }
  }

  handleScroll = () => {
    let scrollToDisplayBanner = 379;
    if (this.state.windowWidth >= 532)
      scrollToDisplayBanner = 352
    if (this.state.windowWidth >= 648)
      scrollToDisplayBanner = 263

    if(window.scrollY <= scrollToDisplayBanner)
      this.setState({stickyBanner: false});
    else {
      this.setState({stickyBanner: true});
      this.setState({bannerWasDisplayed: true});
    }
  }

  bindStickyBanner = () => {
    this.setState({ windowWidth : window.innerWidth })
    if(window.innerWidth >= 810) {
      if(this.state.stickyBanner)
        this.setState({stickyBanner: false});
      window.removeEventListener('scroll', this.handleScroll);
    } else {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.quotes !== this.props.quotes)
      this.setState({quotes: this.props.quotes, featured_quotes: this.props.featured_quotes, filteredQuotes: this.props.quotes.filter(q=> q.product_type === this.state.productType)});

    setTimeout(function(){
      var quoteList = document.querySelector('div.quotes');
      if(quoteList)
        quoteList.classList.remove('d-none');
    }, 50)
  }

  isMobile = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
  };

  sortQuotesByPrice = e => {
    this.setState({ quotes: JSON.parse(sessionStorage.getItem('quotes')) });
    this.toggleBolder(e.target);
  };

  sortQuotesByRating = e => {
    const RAPP = [];
    const RAP = [];
    const RA = [];
    const RAM = [];
    const RBPP = [];
    const RBP = [];
    const RB = [];
    const RBM = [];
    const RCPP = [];
    const RCM = [];
    const RD = [];
    const RE = [];
    const RF = [];
    const RS = [];

    this.state.quotes.forEach(quote => {
      if (quote.rating === 'A++') {
        RAPP.push(quote);
      }
      if (quote.rating === 'A+') {
        RAP.push(quote);
      }
      if (quote.rating === 'A') {
        RA.push(quote);
      }
      if (quote.rating === 'A-') {
        RAM.push(quote);
      }
      if (quote.rating === 'B++') {
        RBPP.push(quote);
      }
      if (quote.rating === 'B+') {
        RBP.push(quote);
      }
      if (quote.rating === 'B') {
        RB.push(quote);
      }
      if (quote.rating === 'B-') {
        RBM.push(quote);
      }
      if (quote.rating === 'C++') {
        RCPP.push(quote);
      }
      if (quote.rating === 'C-') {
        RCM.push(quote);
      }
      if (quote.rating === 'D') {
        RD.push(quote);
      }
      if (quote.rating === 'E') {
        RE.push(quote);
      }
      if (quote.rating === 'F') {
        RF.push(quote);
      }
      if (quote.rating === 'S') {
        RS.push(quote);
      }
    });

    this.setState({
      quotes: [
        ...RAPP,
        ...RAP,
        ...RA,
        ...RAM,
        ...RBPP,
        ...RBP,
        ...RB,
        ...RBM,
        ...RCPP,
        ...RCM,
        ...RD,
        ...RE,
        ...RF,
        ...RS
      ]
    });
    this.toggleBolder(e.target);
  };

  toggleBolder = target => {
    document.querySelector('.sort-bolder').classList.remove('sort-bolder');
    target.classList.add('sort-bolder');
  };

updateCoverage = (e, noTimeout = false) => {
  if(e && e.value) {
    this.setState({ coverage: e.value }, ()=> {
      if(noTimeout)
        this.updatePrices(e.value);
      else
        setTimeout(() => {
          this.updatePrices(e.value);
        }, 200);
    });
  }
}

updatePrices = async (coverage) => {
    if (coverage === this.state.coverage) {
      // Access the instance variable directly
      if (this.cancelToken) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }

      // Initialize the instance variable directly
      this.cancelToken = axios.CancelToken.source();

      const generalInfo = JSON.parse(sessionStorage.getItem('generalInfo'));
      generalInfo.coverage = this.state.coverage;
      generalInfo.path = '/quotes-list';
      sessionStorage.setItem('generalInfo', JSON.stringify(generalInfo));

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + 'quotes',
          {
            general_info: generalInfo,
            requote: true,
            application_id: sessionStorage.getItem('application_id'),
          },
          { cancelToken: this.cancelToken.token }
        );

        const featured_quotes = [
          ...response.data.featured_immediate_coverage_plan,
          ...response.data.featured_guaranteed_issue_plan,
        ];

        sessionStorage.setItem(
          'featured_quotes',
          JSON.stringify(featured_quotes)
        );
        sessionStorage.setItem('quotes', JSON.stringify(response.data.quotes));
        sessionStorage.setItem('application_id', response.data.application_id);

        this.setState({
          quotes: response.data.quotes,
          featured_quotes,
          filteredQuotes: response.data.quotes.filter(
            (q) => q.product_type === this.state.productType
          ),
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
      }
    }
  };

  setCoverageToShow(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  saveQuote = quote => {
    sessionStorage.setItem('quote', JSON.stringify(quote));
    sessionStorage.setItem('mooQuoteGI', this.state.mooQuoteGI);
    sessionStorage.setItem('productType', this.state.productType);
    this.props.updateApplicationState(
      sessionStorage.getItem('application_id'), 
      'quote_selected', 
      quote.name,
      quote.insurance_amount
    );
    this.props.history.push('/application');
  };

  toggleProductType = productType => {
    this.setState({ productType, filteredQuotes: this.state.quotes.filter(q=> q.product_type === productType) }, ()=> {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    })
  };

  setCoverageTooltip = (key, content) => {
    this.setState({ coverageTooltipKey: key, coverageTooltipContent: content })
  };

  render() {

    return (
      <section className="sector position-static col-xs-12 quotesBackground" >
        {this.props.loading && <Loader />}
        
        {(this.isMobile() && this.props.loading) || this.state.quotes.length == 0 ? (
          ''
         ) : (
         <FixedButton text="Edit Information" handleClick={this.goBack} sticky={this.state.stickyBanner} class={"editInformationContainer"} />
         )
        }


        {this.state.quotes.length > 0 && !this.props.loading ? (
          <QuotesLayout
            featured_quotes={this.state.featured_quotes}
            quotes={this.state.quotes}
            competitor_quotes={this.state.competitor_quotes}
            modal={this.state.modal}
            planModal={this.state.planModal}
            coverage={this.state.coverage}
            years={this.state.years}
            livingState={this.state.livingState}
            smoked={this.state.smoked}
            gender={this.state.gender}
            stickyBanner={this.state.stickyBanner}
            bannerWasDisplayed={this.state.bannerWasDisplayed}
            highQuotesEnabled={this.state.highQuotesEnabled}
            filteredQuotes={this.state.filteredQuotes}
            productType={this.state.productType}
            coverageTooltipKey={this.state.coverageTooltipKey}
            coverageTooltipContent={this.state.coverageTooltipContent}
            toggle={this.toggle}
            togglePlanModal={this.togglePlanModal}
            updateCoverage={this.updateCoverage}
            updatePrices={this.updatePrices}
            saveQuote={this.saveQuote}
            sortQuotesByRating={this.sortQuotesByRating}
            sortQuotesByPrice={this.sortQuotesByPrice}
            setCoverageToShow={this.setCoverageToShow}
            isMobile={this.isMobile}
            toggleProductType={this.toggleProductType}
            setCoverageTooltip={this.setCoverageTooltip}
          />
        ) : (this.state.coverage >= 50000) ? 
        !this.props.loading && (
          < DeepQuoteExploration coverage={this.setCoverageToShow(this.state.coverage)} highQuotesEnabled={this.state.highQuotesEnabled}/>
        ):
        !this.props.loading && (
          < NoQuotesLayout highQuotesEnabled={this.state.highQuotesEnabled} />
        )}
      </section>
    );
  }
}

const mapStateToProps  = (state) => ({
  quotes: state.consumer.quotes,
  featured_quotes: state.consumer.featured_quotes,
  loading: state.consumer.loading
})

export default compose(
  connect(mapStateToProps, {getQuotes, setLoading, updateProgressBar, updateApplicationState}), 
  withRouter,
  withMobileDetect)(Quotes);