import React from 'react';
import { isMacOS } from './../../utils/isMacOS';
import { isFirefox } from './../../utils/isFirefox'
import NextArrow from '../../assets/Quiz/NextArrow.svg';
import NextArrowPng from '../../assets/Quiz/NextArrow.png';
import BackArrow from '../../assets/Quiz/BackArrow.svg';
import BackArrowPng from '../../assets/Quiz/BackArrow.png';
import '../../pages/ChildrenApplication/components/ChildrenApplication.css';
import '../../pages/Details/components/DetailsLayout.css';

const QuizNav = props => {

  return (
    <div className="d-flex navButtonsWrapper justify-content-between">
        <div >
          {props.showPrevious && <div onClick={props.previousQuestion} className="d-flex align-items-center backQ justify-content-end">
                <img  className="navImg" src={!isMacOS() && isFirefox() ? BackArrowPng : BackArrow} />
                <div className='cPointer prevQ'>Previous Question</div>
          </div>}
        </div>
      {props.showContinue && <div className={`d-flex align-items-center continueQ justify-content-start ${props.currentValueFullfilled() ? "enabled": "disabled"} questionI${props.currentQuestionI}`} onClick={props.nextQuestion}>
          <div className='cPointer font-weight-bold continue'>Continue</div>
           <img src={ !isMacOS() && isFirefox() ? NextArrowPng : NextArrow } className="navImg cPointer" />
        </div>}
    </div>
  );
};

export default QuizNav;
